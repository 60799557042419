import React, { useContext } from "react";
import { ThemeContext } from "../../ThemeContext";
import DownloadReportPdf from "../../Components/DownloadReportPdf/DownloadReportPdf";
import "./DownloadReport.css";
import Data from "../../shared/regionOfInterestData/data";
import DropDownpProfile from "../../Components/DropDown2/DropDownpProfile";
import OrderReport from "../../Components/OrderReport/OrderReport";
import { CheckBox } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import ToothChart from "../../Components/toothChart/ToothChart";
import ToothCard from "../../Components/toothCard/ToothCard";

const DownloadReport = () => {
  function allowDrop(ev) {
    ev.preventDefault();
  }

  function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  }

  function drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
  }
  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <div
      className={
        isDarkTheme ? "downloadReport-main-dark" : "downloadReport-main-light"
      }
    >
      <div className="downloadReport-left">
        <div id="div1" ondrop="drop(event)" ondragover="allowDrop(event)"></div>
        <DownloadReportPdf isDarkTheme={isDarkTheme} />
      </div>
      <div className="downloadReport-right">
        <Data isDarkTheme={isDarkTheme} />
        <ToothChart isDarkTheme={isDarkTheme} />
        <ToothCard toothNumber="13" isDarkTheme={isDarkTheme} />
      </div>
      {/* <OrderReport /> */}
      {/* <DropDownpProfile /> */}
      {/* <div className="refferal-recommendations">
        <h4>Referral Recommendations</h4>
        <div className="refferal-recommendation-body">
          <div className="general-practioner">
            <span>General Practitioner: </span>
            <span className="referral-teeth-numbers">
              34, 35, 31, 41, 42 , 43
            </span>
          </div>
          <div className="peredontist">
            <span>Peridontist: </span>
            <span className="referral-teeth-numbers">
              34, 35, 31, 41, 42 , 43
            </span>
          </div>
          <div className="prosthodontist">
            <span>Prosthodontist: </span>
            <spa className="referral-teeth-numbers" n>
              34, 35, 31, 41, 42 , 43
            </spa>
          </div>
          <div className="endodontist">
            <span>Endodontist: </span>
            <span className="referral-teeth-numbers">
              34, 35, 31, 41, 42 , 43
            </span>
          </div>
          <div className="cbct">
            <span>CBCT: </span>
            <span className="referral-teeth-numbers">
              34, 35, 31, 41, 42 , 43
            </span>
          </div>
        </div>
      </div>
      <div className="tooth-type">
        <p>Tooth Type</p>
        <div className="tooth-type-section">
          <Checkbox id="missing" sx={{ height: "28px", width: "28px" }} />{" "}
          <label htmlFor="missing">Missing</label>{" "}
        </div>
        <div className="tooth-type-section">
          <Checkbox id="implant" sx={{ height: "28px", width: "28px" }} />{" "}
          <label htmlFor="implant">Implant</label>{" "}
        </div>
        <div className="tooth-type-section">
          <Checkbox id="pontic" sx={{ height: "28px", width: "28px" }} />{" "}
          <label htmlFor="pontic">Pontic</label>{" "}
        </div>
        <div className="tooth-type-section">
          <Checkbox id="root-fragment" sx={{ height: "28px", width: "28px" }} />{" "}
          <label htmlFor="root-fragment">Root Fragment</label>{" "}
        </div>
        <div className="tooth-type-section">
          <Checkbox id="tooth-germ" sx={{ height: "28px", width: "28px" }} />{" "}
          <label htmlFor="tooth-germ">Tooth Germ</label>{" "}
        </div>
        <div className="tooth-type-section">
          <Checkbox id="tooth" sx={{ height: "28px", width: "28px" }} />{" "}
          <label htmlFor="tooth">Tooth</label>{" "}
        </div>
      </div> */}
    </div>
  );
};

export default DownloadReport;
