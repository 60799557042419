import axios from "axios";

// const BASE_URL = "http://localhost:3036/api/v1/";
// const BASE_URL = "https://periox-ai-backend.onrender.com/api/v1/";
const BASE_URL = "https://app.cogx.ai/api/v1/";

export const APIs = {
  signupApi: "user",
  loginApi: "user/login",
  getAllUsersApi: "user/alluser",
  getAllPatientApi: "patient/allpatient",
  addPatientApi: "patient",
  editPatientApi: "patient/editpatient",
  deletePatientApi: "patient/deletepatient/",
  searchPatientApi: "patient/search",
};

export const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "/",
  },
});
const request = () => {
  return axios.create({
    baseURL: BASE_URL,
    timeout: 20000,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
//SignUp API Implementation
export const signupApi = async (
  name,
  lastName,
  email,
  password,
  phone,
  gender,
  dob,
  addressUse,
  addressType,
  streetName,
  city,
  district,
  state,
  postelCode,
  country,
  specialty
) => {
  try {
    const response = await fetch(`${BASE_URL}${APIs.signupApi}`, {
      method: "POST",
      body: JSON.stringify({
        name: name,
        lastName: lastName,
        phone: phone,
        email: email,
        password: password,
        gender: gender,
        dob: dob,
        addressUse: addressUse,
        addressType: addressType,
        streetName: streetName,
        city: city,
        district: district,
        state: state,
        postelCode: postelCode,
        country: country,
        system: "633ee87c9ceffb4e5c279bc4",
        site: "633ee719cf071e8c6a33a7a3",
        role: "Clinician",
        specialty: specialty,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, data };
    } else if (response.status === 409) {
      return { success: false, error: "User already exists" };
    } else {
      return { success: false, error: "Sign-up failed" };
    }
  } catch (ex) {
    console.log("Exception in signup: ", ex);
    return { success: false, error: "An error occurred" };
  }
};
//Login API Implementation
export const loginApi = async (email, password) => {
  try {
    const response = await fetch(`${BASE_URL}${APIs.loginApi}`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Response", data);
      localStorage.setItem("userRole", JSON.stringify(data.userData.role));
      return data;
    } else {
      alert("Login failed.");
    }
  } catch (err) {
    alert(err.message);
    console.log("Exception in login: ", err);
  }
};
export const HttpRequest = {
  // loginApi(data) {
  //   return request().post(`${BASE_URL}${APIs.loginApi}`, data);
  // },
  // signupApi(data) {
  //   return request().post(`${BASE_URL}${APIs.signupApi}`, data);
  // },
  getAllUsersApi() {
    return request().get(`${BASE_URL}${APIs.getAllUsersApi}`);
  },

  getAllPatientApi() {
    return request().get(`${BASE_URL}${APIs.getAllPatientApi}`);
  },

  addPatientApi(patient) {
    return request().post(
      `${BASE_URL}${APIs.addPatientApi}`,
      JSON.stringify(patient)
    );
  },
  // async editPatientApi(id) {
  //   try {
  //     const response = await request().post(
  //       `${BASE_URL}${APIs.editPatientApi}/?type=edit`,
  //       JSON.stringify(id)
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error deleting patient:", error);
  //     throw error;
  //   }
  // },
  async deletePatientApi(id) {
    return request().delete(`${BASE_URL}${APIs.deletePatientApi}${id}`);
  },
  searchPatient(query) {
    return request().get(`${BASE_URL}${APIs.deletePatientApi}`, query);
  },
};
