import React from 'react'
import './chatbox.css'
import Chat from './chat/Chat'
import UserList from './userlist/UserList'
const ChatBox = () => {
    return (
        <div className='chatbox'>
            <div className='chatbox__container__left'>
                {/* <UserList /> */}
            </div>
            <div className='chatbox__container__right'>
                <Chat />
            </div>
        </div>
    )
}

export default ChatBox;