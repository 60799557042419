import React from 'react'
import './CBCTReportPageSidebarItem.css'
import image from './cbctReportPageSidebarItemImage.jpg'

const CBCTReportPageSidebarItem = (props) => {

  const imageArray = [image]
  return (
    
    <div className={`cbct-sidebar-item-container-${props.status}`}>
      <div className='cbct-item-image-container'>
        <img src={imageArray[0]} alt="" className="cbct-item-image" />
        {/* <p className='cbct-item-image-text'>May 4, 2022</p> */}
      </div>
    </div>
  )
}

export default CBCTReportPageSidebarItem
