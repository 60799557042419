import React, { useState, useContext } from "react";
import { ThemeContext } from "../../ThemeContext";
import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CircleIcon from '@mui/icons-material/Circle';
import { Switch as AntSwitch } from "antd";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import IconButton from "@material-ui/core/IconButton";
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import "./regionOfInterest.css";
import { Link } from "react-router-dom";
import TeethButton from "../../shared/teethButton/TeethButton";
import { TextFields } from "@material-ui/icons";
import Data from "../../shared/regionOfInterestData/data"
import ToothCard from "../../Components/toothCard/ToothCard"

export default function RegionOfInterest(props) {
  const [isVisible1, setIsVisible1] = useState(true);
  const [isVisible2, setIsVisible2] = useState(true);
  const {isDarkTheme} = useContext(ThemeContext)
  const dismiss1 = () => {
    setIsVisible1(false);
  };
  const dismiss2 = () => {
    setIsVisible2(false);
  };
  const [checked, setChecked] = useState(false);

  const handleChange = (checked) => {
    setChecked(checked);
  };

  const teethButtonStyle = {
    backgroundColor: "white",
     minWidth: "42px",
     padding: "5px"
  }
  return (
    <div className={isDarkTheme ? "teeth-dark" : "teeth-light"}>
      
      <div className={ isDarkTheme ? "teeth__inner-dark" : "teeth__inner-light"}>
        <div className="teeth__inner__left">
          <Data isDarkTheme={isDarkTheme}/>
          <div className={isDarkTheme ? "teeth__chart-dark" : "teeth__chart-light"}>
            <div className="teeth__chart__title">
              <div className="teeth__chart__title__left">
                <h4 style={{color: isDarkTheme ? "white" : "black"}}>Tooth Chart</h4>
              </div>
              <div className="teeth__chart__title__right">
                <Button variant="contained">
                  <b>Edit teeth numbers</b>
                </Button>
                <Button variant="contained">
                  <b>Choose region of interest</b>
                </Button>
              </div>
            </div>
            <div className="teeth__chart__chart__inner">
            
                <TeethButton teethNumber="01" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="02" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="03" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="04" backgroundColor="rgba(10, 5, 255, 0.2)" teethColor="rgba(10, 5, 255, 0.8)" />
                <TeethButton teethNumber="05" backgroundColor="rgba(10, 5, 255, 0.2)" teethColor="rgba(10, 5, 255, 0.8)" />
                <TeethButton teethNumber="06" backgroundColor="rgba(10, 5, 255, 0.2)" teethColor="rgba(10, 5, 255, 0.8)" />
                <TeethButton teethNumber="07" backgroundColor="rgba(10, 5, 255, 0.2)" teethColor="rgba(10, 5, 255, 0.8)" />
                <TeethButton teethNumber="08" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="09" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="10" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="11" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="12" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="13" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="14" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="15" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="16" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="17" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="18" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="19" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="20" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="21" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="22" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="23" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="24" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="25" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="26" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
 
            </div>
            <div className="teeth__chart__chart__footer">
              <div>
              <CircleIcon style={{fontSize: "12px", color: "#D9D9D9"}} /> <span style={{color: "grey"}}>Healthy</span>
              </div>
              <div>
              <CircleIcon style={{fontSize: "12px", color: "#0A05FF"}} /> <span style={{color: "#0A05FF"}}>Treated</span>
              </div>
              <div>
              <CircleIcon style={{fontSize: "12px", color:"#FF3737"}} /> <span style={{color:"#FF3737"}} >Unhealthy</span>
              </div>
              <div>
              <CloseIcon style={{fontSize: "12px" , color: "grey"}} /> <span style={{color: "grey"}}>Missing</span>
              </div>
            </div>
          </div>
        </div>
        <div className="teeth__inner__right">
        <div className={isDarkTheme ? "refferal-recommendations-dark" : "refferal-recommendations-light"} >
        <h4 style={{color: isDarkTheme ? "white" : "black"}}>Referral Recommendations</h4>
        <div className="refferal-recommendation-body">
          <div className="general-practioner">
            <span>General Practitioner: </span>
            <span className="referral-teeth-numbers">
              34, 35, 31, 41, 42 , 43
            </span>
          </div>
          <div className="peredontist">
            <span>Peridontist: </span>
            <span className="referral-teeth-numbers">
              34, 35, 31, 41, 42 , 43
            </span>
          </div>
          <div className="prosthodontist">
            <span>Prosthodontist: </span>
            <spa className="referral-teeth-numbers" n>
              34, 35, 31, 41, 42 , 43
            </spa>
          </div>
          <div className="endodontist">
            <span>Endodontist: </span>
            <span className="referral-teeth-numbers">
              34, 35, 31, 41, 42 , 43
            </span>
          </div>
          <div className="cbct">
            <span>CBCT: </span>
            <span className="referral-teeth-numbers">
              34, 35, 31, 41, 42 , 43
            </span>
          </div>
        </div>
      </div>
          <div className={isDarkTheme ? "fullImage__cropped-dark"  : "fullImage__cropped-light"}>
            <p>Full images</p>
            <div className="switch">
              <AntSwitch
                defaultChecked
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "ant design" }}
                style={{
                  backgroundColor: checked ? "#6557D3" : "transparent",
                }}
              />
            </div>
            <p>Cropped</p>
          </div>
              <ToothCard toothNumber="13" isDarkTheme={isDarkTheme} />
              <ToothCard toothNumber="14" isDarkTheme={isDarkTheme} />
        <div className={isDarkTheme ? "interest-region-conclusion-dark" : "interest-region-conclusion-light"}>
          <h4 style={{color: isDarkTheme ? "white" : "black"}}>Conclusion</h4>
          <div className="interest-region-conclusion-body">
            <Button style={teethButtonStyle}> <FormatBoldIcon style={{color: "black"}} /> </Button>
            <Button style={teethButtonStyle}> <FormatItalicIcon style={{color: "black"}} /> </Button>
            <Button style={teethButtonStyle}> <FormatUnderlinedIcon style={{color: "black"}} /> </Button>
            <Button style={teethButtonStyle}> <FormatUnderlinedIcon style={{color: "black"}} /> </Button>
            <Button style={{backgroundColor: "white", minWidth: "11%", padding: "5px"}}> <FormatUnderlinedIcon style={{color: "black"}} /> </Button>
            <Button style={teethButtonStyle}> <FormatAlignLeftIcon style={{color: "black"}} /> </Button>
            <Button style={teethButtonStyle}> <FormatAlignRightIcon style={{color: "black"}} /> </Button>
            <Button style={teethButtonStyle}> <FormatAlignJustifyIcon style={{color: "black"}} /> </Button>
            <Button style={teethButtonStyle}> <LinkIcon style={{color: "black"}} /> </Button>
            <Button style={{backgroundColor: "grey", minWidth: "42px", padding: "5px"}}> <LinkOffIcon style={{color: "white"}} /> </Button>
          </div>
          <p>Information provided in this report is for advisory purposes only & cannot be accepted as a diagnosis or treatment plan</p>
        </div>
        <div className={isDarkTheme ? "interest-region-sign-analysis-dark" : "interest-region-sign-analysis-light"}>
          <div className="sign-analysis-heading">
          <h4 style={{color: isDarkTheme ? "white" : "black"}}>Sign this analysis</h4>
            <CloseIcon />
          </div>
          <p>Select add doctor to add analysis</p>
          <div className="interest-region-sign-analysis-body">
            <input type="text" placeholder="Select Date" />
            <input type="text" placeholder="Alex Sanders" />
            <Button variant="contained" style={{textTransform: "none"}}>Add Signature</Button>
          </div>
          <div className="interest-region-sign-analysis-footer">
            <p>Print report without signaure</p>
            <Button variant="contained"  style={{minWidth: "none", textTransform: "none", backgroundColor: "grey", padding: "5px"}}> <EditIcon style={{fontSize: "17px"}} />   Sign</Button>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}
