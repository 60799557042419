import React from "react";
import "./CBCTReportPage.css";
import CBCTReportPageSidebar from "./cbctReportPageSidebar/CBCTReportPageSidebar";
import CBCTReportPageBody from "./cbctReportPageBody/CBCTReportPageBody";
import { Typography } from "@mui/material";

const CBCTReportPage = () => {
  return (
    <div className="cbct-main">
      <Typography
        variant="h3"
        style={{
          backgroundColor: "#CFD0D4ff",
          padding: ".5% 0 .5% 1.7%",
          fontWeight: "600",
        }}
      >
        CBCT
      </Typography>
      <div className="cbct-body">
        <CBCTReportPageSidebar />
        <CBCTReportPageBody />
      </div>
    </div>
  );
};

export default CBCTReportPage;
