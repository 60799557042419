import React, { useContext } from "react";
import { ThemeContext } from "../../ThemeContext";
import ColumnGroupingTable from "../../Components/allPatientsTable/Table";

const AllPatients = () => {
  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <div style={{ backgroundColor: isDarkTheme ? "black" : "#2f2f2f" }}>
      <ColumnGroupingTable />
    </div>
  );
};

export default AllPatients;
