import React from "react";
import { Button } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import person1 from "./../../Assets/images/commonImages/Person1.png";

function Comments(props) {
  let comment = [
    {
      name: "Alan Evans",
      type: "Radiological Report",
    },
    {
      name: "Olivia Harris",
      type: "Third Major Study",
    },
    {
      name: "Gilbert Brwon",
      type: "Radiological Study",
    },
    {
      name: "Jessie Flatcher",
      type: "Third Mini Study",
    },
  ];
  const buttonStyles = {
    height: "25px",
    fontSize: "13px",
    minWidth: "0px",
    padding: "4px",
    backgroundColor: "#1B64C8",
    color: "white",
    textTransform: "none",
    marginLeft: "4px",
  };
  return (
    <>
      <div
        className={
          props.isDarkTheme ? "comment-card-dark" : "comment-card-light"
        }
        style={{ marginTop: "15px", width: "26%" }}
      >
        <div className="cardTitle">
          <h5 className="card-title">Comments</h5>
          <AddOutlinedIcon style={{ color: "black" }} />
        </div>
        <div className="comment-section">
          <div className="comment">
            <Button variant="contained" style={buttonStyles}>
              Patients 4
            </Button>
            <Button variant="contained" style={buttonStyles}>
              Reports 12
            </Button>
            <Button variant="contained" style={buttonStyles}>
              Tasks 4
            </Button>
          </div>
          <div className="comment-body">
            {comment.map((text) => (
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <img
                  src={person1}
                  alt=""
                  style={{
                    height: "20%",
                    width: "20%",
                    borderRadius: "85%",
                  }}
                />
                <div
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    width: "150px",
                  }}
                >
                  <p>
                    <strong>{text.name}</strong>{" "}
                  </p>
                  <p>{text.type}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <a href="/" className="card-link my-3">
          View all
        </a>
      </div>
    </>
  );
}

export default Comments;
