import React from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import "./appointments.css";

function Appointments(props) {
  let timing = [
    {
      time: "10:30 am",
      topic: "Root Canal treatment",
      name: "Marvin McKinnery",
    },
    {
      time: "12:30 pm",
      topic: "Therapist 46, 16, 21",
      name: "Cameron Williamson",
    },
    {
      time: "03:00 pm",
      topic: "Check-up",
      name: "Cameron Williamson",
    },
    {
      time: "04:00 pm",
      topic: "Check-up",
      name: "Krsitin Watson",
    },
    {
      time: "04:00 pm",
      topic: "Check-up",
      name: "Krsitin Watson",
    },
  ];
  return (
    <>
      {/* ***********************First Card***********************  */}
      <div className={props.isDarkTheme ? "appointment-card-dark" : "appointment-card-light"}>
        <div className="cardTitle">
          <h5>Today, Mon May 4</h5>
          <AddOutlinedIcon style={{color: "black"}}/>
        </div>
        <div>
          <h6 style={{ fontSize: "16px" }}>
            <span className="time-left">
              <p>9:45 am</p>{" "}
            </span>{" "}
            Chech-up and cleaning
          </h6>
          {/* Mapping the time schedule  */}
          {timing.map((text) => (
            <div className="time">
              <div className="time-left">
                <p>{text.time}</p>
              </div>
              <div className="time-right">
                <p>{text.topic}</p>
                <p>{text.name}</p>
              </div>
            </div>
          ))}
        </div>
        <a href="/" className="customColor">
          View all
        </a>
      </div>
    </>
  );
}

export default Appointments;
