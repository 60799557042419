import React from 'react'
import './CBCTReportPageSidebar.css'
import CBCTReportPageSidebarItem from '../cbctReportPageSidebarItem/CBCTReportPageSidebarItem'

const CBCTReportPageSidebar = () => {
  return (
    <div className='cbct-reportpage-sidebar-container'>
      <CBCTReportPageSidebarItem status='active' />
      <CBCTReportPageSidebarItem status='deactive' />
      <CBCTReportPageSidebarItem status='deactive' />
      <div className="cbct-report-upload-container">
      <div className="cbct-upload-button">
        <p className='upload-cbct-text'>Upload New CBCT</p>
        <p className='drag-drop-cbct-text'>Drag and drop file or folder here</p>
      </div>
      </div>
    </div>
  )
}

export default CBCTReportPageSidebar
