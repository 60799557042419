import React, { useState , useContext} from "react";
import { ThemeContext } from "../../ThemeContext";
import "./ProfileInfo.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ProfileInfo = () => {
  const [doctorFirstName, setdoctorFirstName] = useState("First");
  const [doctorLastName, setdoctorLastName] = useState("Last");
  const [doctorID, setdoctorID] = useState("12345678");
  const [doctorAbout, setdoctorAbout] = useState("About the doctor");
  const [doctorContact, setdoctorContact] = useState("Contact of doctor");
  const [doctorEmail, setdoctorEmail] = useState("doctor@gmail.com");

  const { isDarkTheme } = useContext(ThemeContext);

  return (
    <div className={isDarkTheme ? "patientProfilePage-dark" : "patientProfilePage-light"}>
      <Link to="/">
      <ArrowBackIcon
        style={{
          color: "black",
          backgroundColor: "#EAECF2",
          borderRadius: "100%",
          fontSize: "36px",
        }}
        
      />
      </Link>
      <div className="profileInfoBody">
        <div className="profileInfoLeft">
          <div>
            <label htmlFor="doctorName" className="profileSectionLabel">
              First Name
            </label>{" "}
            <br />
            <input
              type="text"
              className="profileSectionInput"
              value={doctorFirstName}
            />
          </div>
          <div>
            <label htmlFor="doctorName" className="profileSectionLabel">
              ID
            </label>{" "}
            <br />
            <input
              type="text"
              className="profileSectionInput"
              value={doctorID}
            />
          </div>
          <div>
            <label htmlFor="doctorName" className="profileSectionLabel">
              About
            </label>{" "}
            <br />
            <input
              type="text"
              className="profileSectionInput"
              value={doctorAbout}
            />
          </div>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#EAECF2",
              color: "#717376",
              marginTop: "8vh",
              textTransform: "none",
              width: "143px"
            }}
          >
            Cancel
          </Button>
        </div>
        <div className="profileInfoRight">
          <div>
            <label htmlFor="doctorName" className="profileSectionLabel">
              Last Name
            </label>{" "}
            <br />
            <input
              type="text"
              className="profileSectionInput"
              value={doctorLastName}
            />
          </div>
          <div>
            <label htmlFor="doctorName" className="profileSectionLabel">
              Email
            </label>{" "}
            <br />
            <input
              type="text"
              className="profileSectionInput"
              value={doctorEmail}
            />
          </div>
          <div>
            <label htmlFor="doctorName" className="profileSectionLabel">
              Contact
            </label>{" "}
            <br />
            <input
              type="text"
              className="profileSectionInput"
              value={doctorContact}
            />
          </div>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#1B64C8",
              color: "white",
              marginTop: "8vh",
            //   margin: "auto",
              textTransform: "none",
              width: "143px"
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
