import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppRoutes } from "./Routes/AppRoutes";
import { ThemeProvider } from "./ThemeContext";
import { AuthProvider } from "./contexts/AuthContext";
import { useEffect, useState } from "react";
import "./App.css";
function App() {
  // const [isAppLoading, setIsAppLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsAppLoading(false);
  //   }, 5000);
  // }, []);
  // if (isAppLoading)
  //   return (
  //     <div class="loader">
  //       <div class="outer"></div>
  //       <div class="middle"></div>
  //       <div class="inner"></div>
  //     </div>
  //   );
  // const [toggleColor, settoggleColor] = useState(false);

  return (
    <>
      <ThemeProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<AppRoutes />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
