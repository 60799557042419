import React from "react";
import "./reportBody.css";
import MuiSwitchIOS from "../../Components/ReportComponent/muiSwitchIOS/MuiSwitchIOS";
import { Button } from "@mui/material";
import teethXray1 from "./../../Assets/images/ReportImages/teethXray1.jpg";
import teethXray2 from "./../../Assets/images/ReportImages/teethXray2.jpg";
import teethXray3 from "./../../Assets/images/ReportImages/teethXray3.jpg";
import teethXray4 from "./../../Assets/images/ReportImages/teethXray4.jpg";
import teethXray5 from "./../../Assets/images/ReportImages/teethXray5.jpg";
import teethXray6 from "./../../Assets/images/ReportImages/teethXray6.jpg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const ToothSection = (props) => {
  return (
    <>
      <div className="main-body-right">
        <div className="switch-bars">
          <MuiSwitchIOS label="Show probability condtitions" />
          <MuiSwitchIOS label="Show diagnosis details" />
          <MuiSwitchIOS label="Show problem areas" />
        </div>
        <div className="right-mid">
          <div className="image-top-icons">
            <div className="right-image-top-icons">
              <p className="" id="toothNumber">
                Tooth {props.toothNumber}
              </p>
              <Button
                variant="outlined"
                style={{
                  color: "white",
                  borderColor: "white",
                  fontSize: "10px",
                  marginRight: "5px",
                }}
                size="small"
              >
                1 canal 92%
              </Button>
              <Button
                variant="outlined"
                style={{
                  color: "white",
                  borderColor: "white",
                  fontSize: "10px",
                  marginRight: "5px",
                }}
                size="small"
              >
                1 root 92%
              </Button>
            </div>
            <div className="left-image-top-icons">
              <CloseOutlinedIcon style={{ color: "white" }} />
            </div>
          </div>
          <div className="image-top2-icons">
            <Button
              variant="contained"
              color="secondary"
              sx={{ m: 1 }}
              style={{
                fontSize: "10px",
                width: "32%",
                backgroundColor: "#1A1527ff",
                color: " #673DA5ff",
              }}
            >
              Sign of carries 83%
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ m: 1 }}
              style={{
                fontSize: "10px",
                width: "32%",
                backgroundColor: "#1A1527ff",
                color: " #673DA5ff",
              }}
            >
              Teeth Appliance 83%
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ m: 1 }}
              style={{
                fontSize: "10px",
                width: "32%",
                backgroundColor: "#1A1438ff",
                color: "#673DA5ff",
              }}
            >
              <AddOutlinedIcon
                style={{ fontSize: "20px", marginRight: "4px" }}
              />
              Add Diagnosis
            </Button>
          </div>
          <div className="image-section-areas">
            <div className="imagearea">
              <img src={teethXray1} className="section-images" alt="" />
              <p style={{ color: "grey", fontSize: "12px" }}>Slice1. Caries</p>
            </div>
            <div className="imagearea">
              <img src={teethXray2} className="section-images" alt="" />
              <p style={{ color: "grey", fontSize: "12px" }}>Slice 2. Caries</p>
            </div>
            <div className="imagearea">
              <img src={teethXray3} className="section-images" alt="" />
              <p style={{ color: "grey", fontSize: "12px" }}>Frontal</p>
            </div>
            <div className="imagearea">
              <img src={teethXray4} className="section-images" alt="" />
              <p style={{ color: "grey", fontSize: "12px" }}>Axial</p>
            </div>
            <div className="imagearea">
              <img src={teethXray5} className="section-images" alt="" />
              <p style={{ color: "grey", fontSize: "12px" }}>Mesio-distal</p>
            </div>
            <div className="imagearea">
              <img src={teethXray6} className="section-images" alt="" />
            </div>
          </div>
          <div className="right-bottom-section">
            <div className="right-bottom-section-left">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                size="small"
                style={{
                  fontSize: "10px",
                  backgroundColor: "#1A1438ff",
                  color: "#51488Cff",
                }}
              >
                <strong>
                  <ChatBubbleOutlineOutlinedIcon /> Comment
                </strong>
              </Button>
              <Button
                variant="contained"
                sx={{ m: 1 }}
                size="small"
                style={{
                  fontSize: "10px",
                  backgroundColor: "#1A1438ff",
                  color: "#51488Cff",
                }}
              >
                <ReplayOutlinedIcon /> Reset
              </Button>
            </div>
            <div className="right-bottom-section-left-right">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                size="small"
                style={{ fontSize: "10px", backgroundColor: "#673DA5ff" }}
              >
                <DoneOutlinedIcon /> Approve
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToothSection;
