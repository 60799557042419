import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { ThemeContext } from "../ThemeContext";

const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderColor: "black !important",
    borderRadius: "4px",
  },
}));

const CustomTextField = () => {
  const { isDarkTheme } = useContext(ThemeContext);

  const classes = useStyles();

  return (
    <TextField
      variant="outlined"
      sx={{
        width: "30vw",
        color: isDarkTheme ? "white" : "black",
        backgroundColor: isDarkTheme ? "#333" : "white",
        position: "relative",
        "& input": {
          padding: 0.5, // Remove padding from input element
        },
      }}
      InputProps={{
        endAdornment: (
          <SearchIcon style={{ color: isDarkTheme ? "white" : "black" }} />
        ),
        classes: {
          notchedOutline: classes.notchedOutline,
        },
      }}
      id="standard-textarea"
      placeholder="Search"
      size="small"
    />
  );
};

export default CustomTextField;
