import React from "react";
import { Button } from "@mui/material";
import { CircularProgressbar } from "react-circular-progressbar";
import person2 from "./../../../Assets/images/commonImages/Person2.jpg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import "react-circular-progressbar/dist/styles.css";

function Tasks(props) {
  const percentage = 66;

  const activeButtonStyle = {
    height: "25px",
    fontSize: "13px",
    minWidth: "0px",
    padding: "2px",
    backgroundColor: "#1B64C8",
    textTransform: "none",
  };
  const inactiveButtonStyle = {
    height: "25px",
    fontSize: "13px",
    minWidth: "0px",
    padding: "2px",
    backgroundColor: "#F1F0F5ff",
    color: "black",
    textTransform: "none",
    marginLeft: "10px",
  };
  return (
    <>
      {/* ****************  *******Second Card***********************  */}
      <div className={props.isDarkTheme ? "task-card-dark" : "task-card-light"}>
        <div className="cardTitle">
          <h5 className="card-title">Tasks</h5>
          <AddOutlinedIcon style={{ color: "black" }} />
        </div>
        <div className="card-3">
          {/* <Button variant="contained" style={activeButtonStyle}>
            My 12
          </Button> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                color: "blue",
                fontSize: "14px",
                fontWeight: "600",
                marginRight: "5px",
              }}
            >
              My Tasks
            </p>
            <div
              style={{
                width: "2px",
                height: "16px",
                backgroundColor: "blue",
                margin: "0 5px",
              }}
            />
            <p style={{ color: "grey", fontSize: "14px", fontWeight: "600" }}>
              All Tasks
            </p>
          </div>

          {/* Item One  */}
          <div className="card3-items">
            <div className="task-left">
              <CircularProgressbar value={percentage} text={`${percentage}%`} />
            </div>
            <div className="task-right">
              <p>General Practioner</p>

              <div className="task-right-bottom">
                <img className="task-profile-pics" src={person2} alt="" />
                <i
                  className="task-bottom-icon fa-solid fa-comment"
                  style={{ color: "#DC518Fff" }}
                ></i>
                <p>
                  <strong>May 4</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -----------Item 02  */}
        <div className="card3-items">
          <div className="task-left">
            <CircularProgressbar value={percentage} text={`${percentage}%`} />
          </div>
          <div className="task-right">
            <p>Orthodontist</p>

            <div className="task-right-bottom">
              <img className="task-profile-pics" src={person2} alt="" />
              <i className="task-bottom-icon fa-solid fa-comment"></i>
              <p>
                <strong>May 4</strong>
              </p>
            </div>
          </div>
        </div>
        {/* --------------Item 03  */}
        <div className="card3-items">
          <div className="task-left">
            <CircularProgressbar value={percentage} text={`${percentage}%`} />
          </div>
          <div className="task-right">
            <p className="card-title">Therapist</p>

            <div className="task-right-bottom">
              <img className="task-profile-pics" src={person2} alt="" />
              <i className="task-bottom-icon fa-solid fa-comment"></i>
              <p>
                <strong>May 4</strong>
              </p>
            </div>
          </div>
        </div>
        {/* ---------------Item 04  */}
        <div className="card3-items">
          <div className="task-left">
            <CircularProgressbar value={percentage} text={`${percentage}%`} />
          </div>
          <div className="task-right">
            <p>35,36,46,16,21</p>
            <div className="task-right-bottom">
              <img className="task-profile-pics" src={person2} alt="" />
              <p style={{ color: "grey" }}>
                <strong>May 4</strong>
              </p>
            </div>
          </div>
        </div>
        <a href="/" className="card-link" style={{ marginTop: "50px" }}>
          View all
        </a>
      </div>
    </>
  );
}

export default Tasks;
