import React, { Fragment, useContext } from "react";
import image from "./../../../Assets/images/commonImages/card1.jpg";
import card1 from "./../cardPhoto.jpg";
import { ThemeContext } from "../../../ThemeContext";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import person1 from "./../../../Assets/images/commonImages/Person1.png";
import person2 from "./../../../Assets/images/commonImages/Person2.jpg";
import person3 from "./../../../Assets/images/commonImages/Person3.jpg";
import Card from "../Card";
import { Button } from "@mui/material";
import "./body.css";
import { Link } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function Body() {
  const percentage = 66;
  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <Fragment>
      <div className="body-container body">
        <div className="main-body">
          {/* -------------Main Card-------------  */}
          <div className={isDarkTheme ? "overview-card-dark main-card" : "overview-card-light bottom-card-light main-card"}>
            <div className="cardTitle">
              <h5 className="card-title">CBCT</h5>
              <AddOutlinedIcon style={{color: "black"}} />
            </div>
            <Link to="/cbctReport">
              <img
                src={card1}
                className="card1"
                style={{ width: "98%" }}
                alt="..."
              />
            </Link>
            <div className="card1-footer my-3">
              <img src={image} className="card1-images" alt="" />
              <img src={image} className="card1-images" alt="" />
              <img src={image} className="card1-images" alt="" />
              <img src={image} className="card1-images" alt="" />
              <img src={image} className="card1-images" alt="" />
              <img src={image} className="card1-images" alt="" />
              <img src={image} className="card1-images" alt="" />
              <a href="/" className="card-link">
                View all
              </a>
            </div>
          </div>

          {/* ---------------Comments Section---------------  */}

          <div className={isDarkTheme ? "overview-card-dark " : "overview-card-light"}>
            <div className="cardTitle">
              <h5 className="card-title">Comments</h5>
              <AddOutlinedIcon style={{color: "black"}} />
            </div>
            <div className="comment-section">
              <div className="comment-head">
                <p>Radiological</p>
              </div>
              <div className="comment-body">
                <p className="profile-name my-1">
                  <strong>Ahmad Ali Asghar</strong>
                </p>
                <div className="comments">
                  <img className="profile-pics" src={person1} alt="" />
                  <div className={isDarkTheme ? "comment-box-dark" : "comment-box-light"}>
                    <p>
                      It is clearly seen there that carious cavities are
                      locate...
                    </p>
                  </div>
                </div>
                <div className="comments">
                  <div className={isDarkTheme ? "comment-box-dark" : "comment-box-light"}>
                    <p>Jerome, Have you seen the report? What do you think?</p>
                  </div>
                  <img className="profile-pics" src={person2} alt="" />
                </div>
                <p className="profile-name">
                  <strong>Hamza Bin Shafqat</strong>
                </p>
                <div className="comments">
                  <img className="profile-pics" src={person3} alt="" />
                  <div className= {isDarkTheme ? "comment-box-dark" : "comment-box-light"}>
                    <p>Chewing surface of teeth 16 and 12, are located</p>
                  </div>
                </div>
              </div>
            </div>
            <a href="/" className="card-link">
              View all
            </a>
          </div>
          {/* </div> */}

          {/* ---------------Tasks Section---------------  */}
          <div className={isDarkTheme ? "overview-card-dark" : "overview-card-light"}>
            <div className="cardTitle">
              <h5 className="card-title">Tasks</h5>
              <AddOutlinedIcon style={{color: "black"}} />
            </div>
            <div className="card-3">
              <Button
                variant="contained"
                style={{
                  height: "25px",
                  fontSize: "13px",
                  minWidth: "0px",
                  padding: "2px",
                  backgroundColor: "#1B64C8",
                  textTransform: "none",
                }}
              >
                My 12
              </Button>
              <Button
                variant="contained"
                style={{
                  height: "25px",
                  fontSize: "13px",
                  minWidth: "0px",
                  padding: "2px",
                  backgroundColor: "#F1F0F5ff",
                  color: "black",
                  textTransform: "none",
                  marginLeft: "10px",
                }}
              >
                All 23
              </Button>

              {/* Item One  */}
              <div className="card3-items">
                <div className="task-left">
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                  />
                  
                </div>
                <div className="task-right">
                  <p>Therapist</p>

                  <div className="task-right-bottom">
                    <img className="task-profile-pics" src={person2} alt="" />
                    <i
                      className="task-bottom-icon fa-solid fa-comment"
                      style={{ color: "#DC518Fff" }}
                    ></i>
                    <p>
                      <strong>May 4</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* -----------Item 02  */}
            <div className="card3-items">
              <div className="task-left">
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage}%`}
                />
                
              </div>
              <div className="task-right">
                <p>Therapist</p>

                <div className="task-right-bottom">
                  <img className="task-profile-pics" src={person2} alt="" />
                  <p>
                    <strong>May 4</strong>
                  </p>
                </div>
              </div>
            </div>
            {/* --------------Item 03  */}
            <div className="card3-items">
              <div className="task-left">
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage}%`}
                />
                
              </div>
              <div className="task-right">
                <p className="card-title">Therapist</p>

                <div className="task-right-bottom">
                  <img className="task-profile-pics" src={person2} alt="" />
                  <p>
                    <strong>May 4</strong>
                  </p>
                </div>
              </div>
            </div>
            {/* ---------------Item 04  */}
            <div className="card3-items">
              <div className="task-left">
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage}%`}
                />
                
              </div>
              <div className="task-right">
                <p>Therapist</p>
                <div className="task-right-bottom">
                  <img className="task-profile-pics" src={person2} alt="" />
                  <p style={{ color: "grey" }}>
                    <strong>May 4</strong>
                  </p>
                </div>
              </div>
            </div>
            <a href="/" className="card-link">
              View all
            </a>
          </div>

          {/* ---------------Bottom Card Items---------------  */}

          <Card title="Docs" imageUrl={image} />
          <Card title="PANO, FMX" imageUrl={image} />
          <Card title="IOS" imageUrl={image} />
          <Card title="Dental Photos" imageUrl={image} />
        </div>
      </div>
    </Fragment>
  );
}

export default Body;
