import React, { useContext } from "react";
import { Button } from "@mui/material";
import { ThemeContext } from "../../../ThemeContext";
import { TextField, IconButton } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Card from "../PatientOverviewTable/PatientOverviewTable";
import DropdownMenu from "../Dropdown/dropdown";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import "./body.css";
import { Link } from "react-router-dom";

function Body() {
  const { isDarkTheme } = useContext(ThemeContext);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const monthName = currentDate.toLocaleString("default", { month: "long" });
  const formattedDate = `${monthName} ${day} ${year}`;

  const buttonActiveLight = {
    backgroundColor: "#1B64C8",
    color: "white",
    padding: "8px 15px",
    textTransform: "none",
    minWidth: "8vw",
    borderRadius: "8px",
    fontSize: "14px",
  };
  const buttonActiveDark = {
    backgroundColor: "#1B64C8",
    color: "white",
    padding: "8px 15px",
    textTransform: "none",
    minWidth: "8vw",
    borderRadius: "8px",
    fontSize: "14px",
  };
  const buttonInactiveLight = {
    backgroundColor: "#F0F1F6",
    color: "black",
    padding: "8px 5px",
    textTransform: "none",
    minWidth: "8vw",
    borderRadius: "8px",
    fontSize: "13px",
    maxHeight: "12vh",
  };
  const buttonInactiveDark = {
    backgroundColor: "#4C4C4C",
    color: "black",
    padding: "8px 5px",
    textTransform: "none",
    minWidth: "8vw",
    borderRadius: "8px",
    fontSize: "13px",
    maxHeight: "12vh",
  };
  const inputStyleLight = {
    backgroundColor: "#F0F1F6",
    height: "2.5em",
    padding: "0",
    outline: "none",
    fullWidth: "24vw",
    border: "none",
  };
  const inputStyleDark = {
    backgroundColor: "#4C4C4C",
    height: "2.5em",
    color: "white",
    padding: "0",
    outline: "none",
    fullWidth: "24vw",
    border: "none",
  };
  return (
    <div className={isDarkTheme ? "main-dark" : "main-light"}>
      <div className={isDarkTheme ? "app-dark" : "app-light"}>
        <div className="patientOverviewHeader">
          <p>
            <b>{formattedDate}, 33 y.o ID 23965</b>
          </p>
          <Button
            variant="contained"
            style={isDarkTheme ? buttonActiveDark : buttonActiveLight}
          >
            Upload New Study
          </Button>
        </div>
        <div className="topNavButtons">
          <div className="topNavButtons_left">
            <Link to="/patientOverview">
            <Button
              variant="contained"
              style={isDarkTheme ? buttonInactiveDark : buttonInactiveLight }
              >
              Overview
            </Button>
              </Link>
            <Button
              variant="contained"
              style={isDarkTheme ? buttonActiveDark  : buttonActiveLight }
            >
              History
            </Button>
          </div>
          <div className="topNavButtons_right">
            <DropdownMenu isDarkTheme={isDarkTheme} />
          </div>
        </div>
        <div className="navbarBtnGroup_and_Searchbar">
          <div className="navbarBtnGroup">
            <Button
              variant="contained"
              style={isDarkTheme ? buttonActiveDark : buttonActiveLight}
            >
              All <span></span>
            </Button>
            <Button
              variant="contained"
              id=""
              style={isDarkTheme ? buttonInactiveDark : buttonInactiveLight}
            >
              Patients <span></span>
            </Button>
            <Button
              variant="contained"
              style={isDarkTheme ? buttonInactiveDark : buttonInactiveLight}
            >
              Tasks <span></span>
            </Button>
            <Button
              variant="contained"
              style={isDarkTheme ? buttonInactiveDark : buttonInactiveLight}
            >
              Reports <span></span>
            </Button>
            <Button
              variant="contained"
              style={isDarkTheme ? buttonInactiveDark : buttonInactiveLight}
            >
              Comments <span></span>
            </Button>
          </div>
          <div className="inputField">
            <TextField
              fullWidth
              id="standard-bare"
              variant="outlined"
              defaultValue=""
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchOutlinedIcon />
                  </IconButton>
                ),
              }}
              style={isDarkTheme ? inputStyleDark : inputStyleLight}
            />
          </div>
        </div>

        <div className="today_cardsList">
          <Card />
        </div>
      </div>
    </div>
  );
}

export default Body;
