import { useState, useEffect } from "react";
import { HttpRequest } from "../../config/http";
import { toast } from "react-toastify";

const useAddPatient = () => {
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const monthName = currentDate.toLocaleString("default", { month: "long" });
  const date = `${monthName} ${day}, ${year}`;

  const [storedPatients, setStoredPatients] = useState([
    {
      patientID: 1007,
      patientFirstName: "Wick",
      patientLastName: "John",
      patientDOB: "04/05/2022",
      patientStatus: "Unassigned",
      patientDoctors: "Ali",
      patientLastModifiedDate: "Aug 18, 2022",
    },
    {
      patientID: 1008,
      patientFirstName: "Kinsley",
      patientLastName: "Maze",
      patientDOB: "04/05/2022",
      patientStatus: "Unassigned",
      patientDoctors: "Awais",
      patientLastModifiedDate: "Aug 18, 2022",
    },
    {
      patientID: 1009,
      patientFirstName: "Queens",
      patientLastName: "Elena",
      patientDOB: "04/05/2022",
      patientStatus: "Unassigned",
      patientDoctors: "Rehman",
      patientLastModifiedDate: "Aug 18, 2022",
    },
  ]);

  const [patientData, setPatientData] = useState({
    patientID: "",
    patientFirstName: "",
    patientLastName: "",
    patientDOB: "",
    patientStatus: "Unassigned",
    patientDoctors: "",
    gender: "",
    // patientLastModifiedDate: date,
  });

  const [search, setSearch] = useState("");

  const [editPatientData, setPatientEditData] = useState(null);

  const setPatientsData = (e) => {
    const { name, value } = e.target;
    setPatientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditPatientData = (index) => {
    setPatientEditData({ ...storedPatients[index], index });
    setShowEditPopup(true);
  };

  const handleUpdatePatientData = () => {
    if (
      !editPatientData.patientFirstName ||
      !editPatientData.patientDOB ||
      !editPatientData.patientID
    ) {
      window.alert("Please fill in all the required fields.");
      return;
    }

    const updatedStoreData = [...storedPatients];
    updatedStoreData[editPatientData.index] = editPatientData;
    setStoredPatients(updatedStoreData);
    setPatientEditData(null);
    setShowEditPopup(false);
  };

  const handleDeleteData = (patientId) => {
    console.log(
      "🚀 ~ file: useAddPatient.js:90 ~ handleDeleteData ~ patientId:",
      patientId
    );
    HttpRequest.deletePatientApi(patientId)
      .then(() => {
        alert("Patient data deleted successfully!");
        toast.success("Patient data deleted successfully!");
      })
      .catch((err) => {
        alert("Error");
        toast.error("An error occurred while delet patient data.");
      });
  };
  return {
    search,
    setSearch,
    showAddPopup,
    setShowAddPopup,
    // handleAddPatientData,
    // handleSearch,
    setPatientEditData,
    storedPatients,
    handleEditPatientData,
    setStoredPatients,
    handleDeleteData,
    setPatientsData,
    date,
    showEditPopup,
    setShowEditPopup,
    handleUpdatePatientData,
  };
};

export default useAddPatient;
