import React, { useState, useContext } from "react";
import { Button } from "@mui/material";
import { ThemeContext } from "../../ThemeContext";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddIcon from "@mui/icons-material/Add";
import ShareIcon from "@mui/icons-material/Share";
import GroupIcon from "@mui/icons-material/Group";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import Checkbox from "@mui/material/Checkbox";
import LinearLoader from "../../shared/loader/LinearLoader";
import "./Tasks.css";
import CBCTConclusionCard from "../../shared/cbctCard/CBCTConclusionCard";

const PatientProfile = () => {
  const { isDarkTheme } = useContext(ThemeContext);
  const [doctors, setDoctors] = useState([
    { name: "Bell Thomas", specs: "Prothodontist" },
    { name: "King Kristina", specs: "Endodontist" },
    { name: "Prince Ann", specs: "General Practitioner" },
  ]);

  const handleAddDoctor = () => {
    const newDoctorName = "Glover David";
    const newDoctorSpecs = "Orthodontsist";
    setDoctors([...doctors, { name: newDoctorName, specs: newDoctorSpecs }]);
  };
  const [accountActive, setAccountActive] = useState(false);
  const [organizationActive, setOrganizationActive] = useState(false);
  const [staffActive, setStaffActive] = useState(false);
  const [networkActive, setNetworkActive] = useState(false);
  const [cbctActive, setCbctActive] = useState(false);
  const [projectActive, setProjectActive] = useState(true);
  const [stlActive, setStlActive] = useState(false);

  const handleAccountClick = () => {
    setAccountActive(true);
    setOrganizationActive(false);
    setStaffActive(false);
    setNetworkActive(false);
    setCbctActive(false);
    setProjectActive(false);
    setStlActive(false);
  };

  const handleOrganizationClick = () => {
    setAccountActive(false);
    setOrganizationActive(true);
    setStaffActive(false);
    setNetworkActive(false);
    setCbctActive(false);
    setProjectActive(false);
    setStlActive(false);
  };

  const handleStaffClick = () => {
    setAccountActive(false);
    setOrganizationActive(false);
    setStaffActive(true);
    setNetworkActive(false);
    setCbctActive(false);
    setProjectActive(false);
    setStlActive(false);
  };

  const handleNetworkClick = () => {
    setAccountActive(false);
    setOrganizationActive(false);
    setStaffActive(false);
    setNetworkActive(true);
    setCbctActive(false);
    setProjectActive(false);
    setStlActive(false);
  };
  const handleCbctClick = () => {
    setAccountActive(false);
    setOrganizationActive(false);
    setStaffActive(false);
    setNetworkActive(false);
    setCbctActive(true);
    setProjectActive(false);
    setStlActive(false);
  };
  const handleProjectClick = () => {
    setAccountActive(false);
    setOrganizationActive(false);
    setStaffActive(false);
    setNetworkActive(false);
    setCbctActive(false);
    setProjectActive(true);
    setStlActive(false);
  };
  const handleStlClick = () => {
    setAccountActive(false);
    setOrganizationActive(false);
    setStaffActive(false);
    setNetworkActive(false);
    setCbctActive(false);
    setProjectActive(false);
    setStlActive(true);
  };

  return (
    <>
      <div
        className={
          isDarkTheme ? "patient-profile-dark" : "patient-profile-light"
        }
      >
        <div className="patient-profile-body">
          <div className="patient-left-profile">
            <div
              className={
                isDarkTheme
                  ? "patient-profile-card-dark"
                  : "patient-profile-card-light"
              }
            >
              <div className="patient-profile-card-upper">
                <div className="profile-image"></div>
                <p className="patient-profile-name">Patient N01</p>
                <p className="patient-profile-id">ID 1008</p>
                <div className="patient-profile-dob">
                  <span>18/06/1997 </span>
                  <span>(25 y.o)</span>
                </div>
                <div
                  className={
                    isDarkTheme
                      ? "patient-profile-card-footer-dark"
                      : "patient-profile-card-footer-light"
                  }
                >
                  <Button variant="contained">Edit</Button>
                  <Button variant="contained">Share</Button>
                </div>
              </div>
              <div className="patient-profile-card-bottom">
                <div
                  className={
                    isDarkTheme
                      ? "treating-doctors-dark"
                      : "treating-doctors-light"
                  }
                >
                  <span>Treating Doctors</span>
                  <Button variant="contained" onClick={handleAddDoctor}>
                    <AddIcon
                      style={{
                        fontSize: "14px",
                        color: isDarkTheme ? "white" : "black",
                      }}
                    />{" "}
                    Add
                  </Button>
                </div>
                <div className="doctors-list">
                  {doctors.map((doctor) => {
                    return (
                      <div className="doctor">
                        <div className="doctor-left"></div>
                        <div className="doctor-right">
                          <p>{doctor.name}</p>
                          <p className="doctor-specs">{doctor.specs}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  className={
                    isDarkTheme
                      ? "share-with-footer-dark"
                      : "share-with-footer-light"
                  }
                >
                  <span>Shared with</span>
                  <Button variant="contained">
                    <ShareIcon
                      style={{
                        fontSize: "14px",
                        color: isDarkTheme ? "white" : "black",
                      }}
                    />{" "}
                    Share
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="patient-right-profile">
            <div
              className={
                isDarkTheme
                  ? "patient-profile-navbar-dark"
                  : "patient-profile-navbar-light"
              }
            >
              <div
                className={
                  isDarkTheme
                    ? `patient-profile-buttons-dark-${
                        projectActive ? "active" : "deactive"
                      }`
                    : `patient-profile-buttons-light-${
                        projectActive ? "active" : "deactive"
                      }`
                }
                onClick={handleProjectClick}
              >
                <p>Project</p>
              </div>
              <div
                className={
                  isDarkTheme
                    ? `patient-profile-buttons-dark-${
                        organizationActive ? "active" : "deactive"
                      }`
                    : `patient-profile-buttons-light-${
                        organizationActive ? "active" : "deactive"
                      }`
                }
                onClick={handleOrganizationClick}
              >
                <p>Dental Photos</p>
              </div>
              <div
                className={
                  isDarkTheme
                    ? `patient-profile-buttons-dark-${
                        staffActive ? "active" : "deactive"
                      }`
                    : `patient-profile-buttons-light-${
                        staffActive ? "active" : "deactive"
                      }`
                }
                onClick={handleStaffClick}
              >
                <p>FMX</p>
              </div>
              <div
                className={
                  isDarkTheme
                    ? `patient-profile-buttons-dark-${
                        accountActive ? "active" : "deactive"
                      }`
                    : `patient-profile-buttons-light-${
                        accountActive ? "active" : "deactive"
                      }`
                }
                onClick={handleAccountClick}
              >
                <p>PANO</p>
              </div>
              <div
                className={
                  isDarkTheme
                    ? `patient-profile-buttons-dark-${
                        cbctActive ? "active" : "deactive"
                      }`
                    : `patient-profile-buttons-light-${
                        cbctActive ? "active" : "deactive"
                      }`
                }
                onClick={handleCbctClick}
              >
                <p>CBCT</p>
              </div>
              <div
                className={
                  isDarkTheme
                    ? `patient-profile-buttons-dark-${
                        stlActive ? "active" : "deactive"
                      }`
                    : `patient-profile-buttons-light-${
                        stlActive ? "active" : "deactive"
                      }`
                }
                onClick={handleStlClick}
              >
                <p>STL</p>
              </div>
              <div
                className={
                  isDarkTheme
                    ? `patient-profile-buttons-dark-${
                        networkActive ? "active" : "deactive"
                      }`
                    : `patient-profile-buttons-light-${
                        networkActive ? "active" : "deactive"
                      }`
                }
                onClick={handleNetworkClick}
              >
                <p>DOCS</p>
              </div>
              <Button variant="contained" startIcon={<AddIcon />}>
                New
              </Button>
            </div>
            <div
              className={
                isDarkTheme
                  ? "right-profile-body-dark"
                  : "right-profile-body-light"
              }
            >
              {projectActive ? (
                <>
                  <div className="right-profile-body-left">
                    <p className="due-date">Due Date</p>
                    <div className="right-profile-body-date">
                      <CalendarTodayIcon style={{ fontSize: "16px" }} />
                      <span> 01 Jan 2021</span>
                    </div>
                    <h6 className="clinical-description">
                      Clinical Case Description
                    </h6>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Architecto vitae unde et, veritatis, soluta aut accusamus
                      consectetur fuga similique <br /> doloremque, quisquam
                      tempora aspernatur blanditiis suscipit! Esse aliquam
                      veritatis facilis praesentium laudantium quas!
                    </p>
                    <p className="edit-description">Edit Description</p>
                  </div>
                  <div className="right-profile-body-right">
                    <p className="todo-list-text">Todo List</p>
                    <LinearLoader />
                    <div className="todo-task">
                      <div className="todo-task-heading">
                        <div>
                          <Checkbox
                            size="small"
                            color="secondary"
                            style={{ height: "20px", width: "20px" }}
                          />{" "}
                          <span>CBCT Diagnostice</span>{" "}
                        </div>
                        <FormatAlignLeftIcon style={{ fontSize: "16px" }} />
                      </div>
                      <div className="todo-task-description"></div>
                      <div className="todo-task-creater">
                        <GroupIcon
                          style={{ fontSize: "18px", marginRight: "10px" }}
                        />
                        <CalendarTodayIcon style={{ fontSize: "16px" }} />
                      </div>
                    </div>
                    <div className="todo-task">
                      <div className="todo-task-heading">
                        <div>
                          <Checkbox
                            size="small"
                            color="secondary"
                            style={{ height: "20px", width: "20px" }}
                          />{" "}
                          <span>CBCT Diagnostice</span>{" "}
                        </div>
                        <FormatAlignLeftIcon style={{ fontSize: "16px" }} />
                      </div>
                      <div className="todo-task-description">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </div>
                      <div className="todo-task-creater">
                        <div className="todo-task-profile"></div>{" "}
                        <span>King K.</span>
                        <CalendarTodayIcon
                          style={{ fontSize: "16px", marginLeft: "12px" }}
                        />
                        <span>01 Sep 2022</span>
                      </div>
                    </div>
                    <div className="todo-task">
                      <div className="todo-task-heading">
                        <div>
                          <Checkbox
                            size="small"
                            color="secondary"
                            style={{ height: "20px", width: "20px" }}
                          />{" "}
                          <span>CBCT Diagnostice</span>{" "}
                        </div>
                        <FormatAlignLeftIcon style={{ fontSize: "16px" }} />
                      </div>
                      <div className="todo-task-description">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </div>
                      <div className="todo-task-creater">
                        <div className="todo-task-profile"></div>{" "}
                        <span>King K.</span>
                        <CalendarTodayIcon
                          style={{ fontSize: "16px", marginLeft: "12px" }}
                        />
                        <span>01 Sep 2022</span>
                      </div>
                    </div>
                    <div className="todo-task">
                      <div className="todo-task-heading">
                        <div>
                          <Checkbox
                            size="small"
                            color="secondary"
                            style={{ height: "20px", width: "20px" }}
                          />{" "}
                          <span>CBCT Diagnostice</span>{" "}
                        </div>
                        <FormatAlignLeftIcon style={{ fontSize: "16px" }} />
                      </div>
                      <div className="todo-task-description"></div>
                      <div className="todo-task-creater">
                        <div className="todo-task-profile"></div>{" "}
                        <span>King K.</span>
                        <CalendarTodayIcon
                          style={{ fontSize: "16px", marginLeft: "12px" }}
                        />
                        <span>01 Sep 2022</span>
                      </div>
                    </div>
                    <div className="todo-task">
                      <div className="todo-task-heading">
                        <div>
                          <Checkbox
                            size="small"
                            color="secondary"
                            style={{ height: "20px", width: "20px" }}
                          />{" "}
                          <span>CBCT Diagnostice</span>{" "}
                        </div>
                        <FormatAlignLeftIcon style={{ fontSize: "16px" }} />
                      </div>
                      <div className="todo-task-description"></div>
                      <div className="todo-task-creater">
                        <div className="todo-task-profile"></div>{" "}
                        <span>King K.</span>
                        <CalendarTodayIcon
                          style={{ fontSize: "16px", marginLeft: "12px" }}
                        />
                        <span>01 Sep 2022</span>
                      </div>
                    </div>
                    <div>
                      <Checkbox
                        size="small"
                        color="secondary"
                        style={{ height: "20px", width: "20px" }}
                      />{" "}
                      <span>Add Todo</span>{" "}
                    </div>
                  </div>
                </>
              ) : (
                <CBCTConclusionCard isDarkTheme={isDarkTheme} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PatientProfile;
