import React from "react";

import 'react-circular-progressbar/dist/styles.css';
import "./topcard.css";
function TopCard({ imageUrl, description }) {
  return (
    <div className="cards">
      <img src={imageUrl} alt="Card" className="card-image" />
      <p className="card-description">{description}</p>
    </div>
  );
}

export default TopCard;
