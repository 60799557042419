import React, { useState } from "react";
import "./AccountInfo.css";
import { Typography } from "@mui/material";

const AccountInfo = (props) => {
    const [fullName, setFullName] = useState("Alex Sander")
    const [email, setEmail] = useState("alexsander@gmail.com")
    const [dentalFormat, setDentalFormat] = useState("Universal")
  return (
    <div className="account-info-body">
      <div className="account-info-body-left">
        <Typography variant="h6" sx={{color: props.isDarkTheme? "white" : "black" }}>Account Info: </Typography>
        <div className={props.isDarkTheme ? "account-info-body-left-item-dark": "account-info-body-left-item-light"}>
            <label htmlFor="Name">Full Name: </label> <input type="text" value={fullName} />
        </div>
        <div className={props.isDarkTheme ? "account-info-body-left-item-dark": "account-info-body-left-item-light"}>
            <label htmlFor="Name">Email: </label> <input type="text" value={email} />
        </div>
      </div>
      <div className="account-info-body-right">
        <Typography variant="h6" sx={{color: props.isDarkTheme? "white" : "black" }}>Data Setting: </Typography>
        <div className={props.isDarkTheme ? "account-info-body-right-item-dark": "account-info-body-right-item-light"}>
            <label htmlFor="Name">Dental Notation Format: </label> <input style={{width: "67%"}} type="text" value={dentalFormat} />
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
