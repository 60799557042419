import React, { useState } from "react";
import { Button } from "@mui/material";
function ToothButton(props) {
  const [style, setStyle] = useState({ color: "black" });

  const handleClick = () => {
    setStyle({ color: "red" });
  };
  return (
    <Button
      variant="contained"
      size="small"
      // onClick={()=> {style={color: "red"}}}
      onClick={handleClick}
      style={style}
    >
      {/* <i class="fa-thin fa-tooth" style={{height: "50px", width: "20[x"}}></i> */}
      {props.toothNumber}
    </Button>
  );
}

export default ToothButton;
