import React, { useContext } from "react";
import { ThemeContext } from "../../ThemeContext";
import person1 from "./../../Assets/images/commonImages/Person1.png";
import person2 from "./../../Assets/images/commonImages/Person2.jpg";
import person3 from "./../../Assets/images/commonImages/Person3.jpg";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import PanoramaHorizontalOutlinedIcon from "@mui/icons-material/PanoramaHorizontalOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Button } from "@mui/material";
import CustomTextField from "../../shared/CustomTextField";

function PatientList() {
  let patients = [
    {
      name: "Matt Smith",
      profile: person1,
      ID: 12587019,
      monthDate: "September 9",
      year: 1988,
      age: 33,
    },
    {
      name: "Peter Parker",
      profile: person2,
      ID: 1117989,
      monthDate: "April 14",
      year: 1975,
      age: 47,
    },
    {
      name: "Monica Carrington",
      profile: person3,
      ID: 12993879,
      monthDate: "June 10",
      year: 1999,
      age: 23,
    },
    {
      name: "Nicole Clifford",
      profile: person1,
      ID: 12003879,
      monthDate: "September 11",
      year: 1984,
      age: 38,
    },
    {
      name: "Alan Evans",
      profile: person2,
      ID: 13889876,
      monthDate: "May 1",
      year: 1962,
      age: 60,
    },

    {
      name: "Alan Evans",
      profile: person2,
      ID: 13889876,
      monthDate: "May 1",
      year: 1962,
      age: 60,
    },

    {
      name: "Alan Evans",
      profile: person2,
      ID: 13889876,
      monthDate: "May 1",
      year: 1962,
      age: 60,
    },

    {
      name: "Alan Evans",
      profile: person2,
      ID: 13889876,
      monthDate: "May 1",
      year: 1962,
      age: 60,
    },
  ];

  const iconButtonStyle = {
    color: "black",
    height: "30px",
    width: "30px",
    marginRight: "8px",
  };
  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <>
      {/* ***********************Fourth Card***********************  */}
      <div
        className={isDarkTheme ? "patient-list-dark" : "patient-list-light"}
        style={{ marginTop: "15px" }}
      >
        <div className="patient_Top_section">
          <p
            style={{
              fontSize: "22px",
              fontWeight: "700",
              marginBottom: "0.5em",
              marginLeft: 0,
            }}
          >
            Patients
          </p>
          <AddOutlinedIcon style={{ color: "black", cursor: "pointer" }} />
        </div>

        <div className="cardTitle-left">
          <div className="cardTitle-left-buttons">
            <Button
              variant="contained"
              style={{
                fontSize: "13px",
                padding: "4px 7px",
                backgroundColor: "#1B64C8",
                textTransform: "none",
              }}
            >
              My Patients
            </Button>
            <Button
              variant="contained"
              style={{
                fontSize: "13px",
                padding: "4px 7px",
                backgroundColor: "white",
                color: "grey",
                textTransform: "none",
                marginLeft: "10px",
              }}
            >
              All Patients
            </Button>
            <Button
              variant="contained"
              style={{
                fontSize: "13px",
                padding: "4px 7px",
                backgroundColor: "white",
                color: "grey",
                textTransform: "none",
                marginLeft: "10px",
              }}
            >
              Favorite
            </Button>
          </div>
          <CustomTextField />
        </div>
        {patients.map((text) => (
          <div
            className="patients"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <StarOutlineIcon style={{ color: "black", marginTop: "10px" }} />
              <img
                src={text.profile}
                alt=""
                style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "100%",
                }}
              />

              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "13px",
                  width: "12vw",
                }}
              >
                <p>
                  <strong>{text.name}</strong>{" "}
                </p>
                <p>ID {text.ID}</p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                fontSize: "13px",
                width: "12vw",
              }}
            >
              <div>
                <p>{text.monthDate}</p>
                <p>
                  {text.year}, {text.age}, y.o
                </p>
              </div>
            </div>
            <div className="icons" style={{ marginLeft: "30px" }}>
              <Button style={{ minWidth: "0" }}>
                <ZoomOutMapOutlinedIcon style={iconButtonStyle} />
              </Button>

              <Button style={{ minWidth: "0" }}>
                <PanoramaHorizontalOutlinedIcon style={iconButtonStyle} />
              </Button>
              <Button style={{ minWidth: "0" }}>
                <InsertDriveFileOutlinedIcon style={iconButtonStyle} />
              </Button>
            </div>
            <Button style={{ minWidth: "0" }}>
              <MoreHorizOutlinedIcon style={iconButtonStyle} />
            </Button>
          </div>
        ))}
        <a href="/" className="card-link my-1">
          View all
        </a>
      </div>
    </>
  );
}

export default PatientList;
