import React, {useContext} from 'react';
import { ThemeContext } from '../../../ThemeContext';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button } from '@mui/material';
import "./staffTable.css";

function createData(doctorName, doctorJobTitle, doctorSpecs, doctorLastActivity, doctorRole) {
  return { doctorName, doctorJobTitle, doctorSpecs, doctorLastActivity, doctorRole };
}

const rows = [
  createData('Alex Sander', 'CMO', 'Prosthodontist', 24, 4.0),
  createData('Johnathan Wick', 'Dentist', 'Endodontist', 37, 4.3),
  createData('Donald Trump', 'Dentist', 'Oral Surgeon', 24, 6.0),
  createData('Borack Obama', 'Dentist', 'Ortho Dontist', 67, 4.3),
  createData('Micheal Watson', 'Dentist', 'Oral Radiologist', 49, 3.9),
];

export default function CustomizedTables(props) {
  const {isDarkTheme} = useContext(ThemeContext)
  return (
    <>
    <div className="settings-table">
        <div className="settings-table-header">
        <input
          type="text"
          placeholder="Search"
          className={props.isDarkTheme ? "settings-search-field-dark" : "settings-search-field-light"}
        />
        <Button variant="contained" className='add-doctor-button'>Add doctor</Button>
        </div>
      </div>
    <TableContainer component={Paper} style={{ backgroundColor: props.isDarkTheme? "#1e1e20" : "white",padding: "1%"}}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell isDarkTheme={isDarkTheme} style={{width: "35%"}}>Name</StyledTableCell>
            <StyledTableCell isDarkTheme={isDarkTheme}>Job Title</StyledTableCell>
            <StyledTableCell isDarkTheme={isDarkTheme}>Specification</StyledTableCell>
            <StyledTableCell isDarkTheme={isDarkTheme}>Last Activity</StyledTableCell>
            <StyledTableCell isDarkTheme={isDarkTheme}>Role</StyledTableCell>
            <StyledTableCell isDarkTheme={isDarkTheme}>Assets</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{backgroundColor: "#2E2B30"}}>
          {rows.map((row) => (
            <StyledTableRow key={row.doctorName} isDarkTheme={isDarkTheme}>
              <StyledTableCell component="th" scope="row" isDarkTheme={isDarkTheme}>
                {row.doctorName}
              </StyledTableCell>
              <StyledTableCell isDarkTheme={isDarkTheme}>{row.doctorJobTitle}</StyledTableCell>
              <StyledTableCell isDarkTheme={isDarkTheme} >{row.doctorSpecs}</StyledTableCell>
              <StyledTableCell isDarkTheme={isDarkTheme}>{row.doctorLastActivity}</StyledTableCell>
              <StyledTableCell isDarkTheme={isDarkTheme}>{row.doctorRole}</StyledTableCell>
              <StyledTableCell  isDarkTheme={isDarkTheme} align="right"><MoreVertIcon /></StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme, isDarkTheme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: isDarkTheme  ? "#2E2B30" : "white",
    color: isDarkTheme  ? "white" : "black",
    borderBottom: "none"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: isDarkTheme? "#2E2B30" : "white",
    color: isDarkTheme ? "white" : "black",
    borderBottom: "0",
    padding: "18px"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, isDarkTheme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: isDarkTheme? "aliceblue" : "#121116",
    borderBottom: "0",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));