import React, { useContext, useEffect, useState } from "react";
import useAddPatient from "./useAddPatient";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ThemeContext } from "../../ThemeContext";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import TableRow from "@mui/material/TableRow";
import ContactsIcon from "@mui/icons-material/Contacts";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import PanoramaHorizontalOutlinedIcon from "@mui/icons-material/PanoramaHorizontalOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import AddPatientModal from "./AddPatientModal";
import { Button, TablePagination } from "@mui/material";
import { HttpRequest } from "../../config/http";
import { styled as muiStyled } from "@mui/material/styles";
import "./Table.css";

// function checkStatus(statusText) {
//   if (statusText.innerHTML === "Unassigned") {
//     statusText.style.color = "Red";
//   } else if (statusText.innerHTML === "Case finished") {
//     statusText.style.color = "Purple";
//   } else if (statusText.innerHTML === "Under treatment") {
//     statusText.style.color = "Green";
//   } else if (statusText.innerHTML === "In review") {
//     statusText.style.color = "Yellow";
//   } else {
//     return statusText;
//   }
//   return statusText;
// }

export default function ColumnGroupingTable() {
  const [userData, setUserData] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const handleDoctorSelect = (selectedOption) => {
    setSelectedDoctor(selectedOption);
  };
  const { isDarkTheme } = useContext(ThemeContext);
  let count = 1;
  const patientsButtonStyle = {
    backgroundColor: "#1B64C8",
    padding: "0px 10px",
    textTransform: "none",
    fontSize: "15px",
    color: "white",
  };
  useEffect(() => {
    fetchPatientData();
  }, []);
  // Get All Patients Data
  const fetchPatientData = () => {
    HttpRequest.getAllPatientApi()
      .then((response) => {
        let patients = response.data.data;
        setUserData(patients);
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {
    search,
    setSearch,
    showAddPopup,
    setShowAddPopup,
    storedPatients,
    handleEditPatientData,
    handleDeleteData,
    showEditPopup,
    setShowEditPopup,
    setPatientEditData,
    handleUpdatePatientData,
  } = useAddPatient();
  // Calculate the count based on the filtered and paginated data
  const filteredAndPaginatedData = storedPatients.filter((item) => {
    const searchTerm = search.toLowerCase();
    return (
      searchTerm === "" ||
      item.patientFirstName.toLowerCase().includes(searchTerm) ||
      item.patientDoctors.toLowerCase().includes(searchTerm) ||
      item.patientLastName.toLowerCase().includes(searchTerm)
    ); // Convert patientID to string and check for inclusion
  });

  const rowCount = filteredAndPaginatedData.length;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        marginTop: "10vh",
        backgroundColor: isDarkTheme ? "black" : "white",
        boxShadow:
          "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)",
      }}
      className="patient-table"
    >
      <div
        className={isDarkTheme ? "table-head-dark" : "table-head-light"}
        sx={{ backgroundColor: isDarkTheme ? "black" : "#f0f1f6" }}
      >
        <input
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search patients name, or doctor name"
          className={isDarkTheme ? "search-field-dark" : "search-field-light"}
        />

        <Button
          style={patientsButtonStyle}
          onClick={() => setShowAddPopup(true)}
        >
          Add New Patient
        </Button>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            backgroundColor: isDarkTheme ? "#2C2C2C" : "white",
            color: isDarkTheme ? "white" : "black",
          }}
          className={isDarkTheme ? "dark-theme" : "light-theme"}
        />
      </div>
      <TableContainer component={Paper} className="patient-table-container">
        <Table
          sx={{ minWidth: "75vw" }}
          aria-label="customized table"
          className="patient-table-table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell
                isDarkTheme={isDarkTheme}
                style={{ width: "15px" }}
              >
                {" "}
              </StyledTableCell>
              <StyledTableCell
                isDarkTheme={isDarkTheme}
                style={{ width: "100px" }}
              >
                Patient ID{" "}
              </StyledTableCell>
              <StyledTableCell isDarkTheme={isDarkTheme} align="left">
                Patient Name
              </StyledTableCell>
              <StyledTableCell
                isDarkTheme={isDarkTheme}
                style={{ width: "100px" }}
                align="left"
              >
                Date of birth
              </StyledTableCell>
              <StyledTableCell
                isDarkTheme={isDarkTheme}
                style={{ width: "100px" }}
                align="left"
              >
                Contact No
              </StyledTableCell>
              <StyledTableCell
                isDarkTheme={isDarkTheme}
                style={{ width: "100px" }}
                align="left"
              >
                Gender
              </StyledTableCell>
              <StyledTableCell
                isDarkTheme={isDarkTheme}
                style={{ width: "285px" }}
                align="left"
              >
                Study Type
              </StyledTableCell>
              <StyledTableCell
                isDarkTheme={isDarkTheme}
                align="left"
                style={{ maxWidth: "100px" }}
              >
                Refer Doctors
              </StyledTableCell>
              <StyledTableCell isDarkTheme={isDarkTheme} align="left">
                Actions
              </StyledTableCell>
              <StyledTableCell isDarkTheme={isDarkTheme}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.map((data, index) => (
              <StyledTableRow key={data._id}>
                <StyledTableCell isDarkTheme={isDarkTheme} align="left">
                  <StarIcon />
                </StyledTableCell>
                <StyledTableCell isDarkTheme={isDarkTheme} align="left">
                  {count++}
                </StyledTableCell>
                <StyledTableCell isDarkTheme={isDarkTheme} align="left">
                  {data.firstName} {data.lastName}
                </StyledTableCell>
                <StyledTableCell isDarkTheme={isDarkTheme} align="left">
                  {data.dateOfBirth}
                </StyledTableCell>
                <StyledTableCell isDarkTheme={isDarkTheme} align="left">
                  {data.contactNo}
                </StyledTableCell>
                <StyledTableCell isDarkTheme={isDarkTheme} align="left">
                  {data.Gender}
                </StyledTableCell>
                {/* <StyledTableCell isDarkTheme={isDarkTheme} align="left">
                  {checkStatus(data.patientStatus)}
                </StyledTableCell> */}
                <StyledTableCell align="left" isDarkTheme={isDarkTheme}>
                  <div className="patient-table-icons">
                    <Button style={{ minWidth: "0" }}>
                      <ContactsIcon
                        style={{ color: isDarkTheme ? "white" : "black" }}
                      />
                    </Button>
                    <Button style={{ minWidth: "0" }}>
                      <CameraAltOutlinedIcon
                        style={{ color: isDarkTheme ? "white" : "black" }}
                      />
                    </Button>
                    <Button style={{ minWidth: "0" }}>
                      <ZoomOutMapOutlinedIcon
                        style={{ color: isDarkTheme ? "white" : "black" }}
                      />
                    </Button>
                    <Button style={{ minWidth: "0" }}>
                      <PanoramaHorizontalOutlinedIcon
                        style={{ color: isDarkTheme ? "white" : "black" }}
                      />
                    </Button>
                    <Button style={{ minWidth: "0" }}>
                      <InsertDriveFileOutlinedIcon
                        style={{ color: isDarkTheme ? "white" : "black" }}
                      />
                    </Button>
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  isDarkTheme={isDarkTheme}
                  style={{ whiteWrap: "wrap" }}
                >
                  {data.referDoctor.map((doctor, index) => (
                    <div key={index}>- {doctor.name}</div>
                  ))}
                </StyledTableCell>
                {/* <StyledTableCell isDarkTheme={isDarkTheme} align="left">
                  {data.patientLastModifiedDate}
                </StyledTableCell> */}
                <StyledTableCell isDarkTheme={isDarkTheme}>
                  <DeleteIcon
                    onClick={() => handleDeleteData(data._id)}
                    style={{
                      color: isDarkTheme ? "white" : "black",
                      cursor: "pointer",
                    }}
                  />
                  {/* <div className="delete-button-container">
                    <DeleteIcon
                      onClick={() => handleDeleteData(index)}
                      className={`delete-icon ${
                        isDarkTheme ? "dark" : "light"
                      }`}
                    />
                    <span className="delete-tooltip">Delete</span>
                  </div> */}
                  <EditIcon
                    onClick={() => handleEditPatientData(index)}
                    style={{
                      color: isDarkTheme ? "white" : "black",
                      cursor: "pointer",
                    }}
                  />
                  {/* <div className="edit-button-container">
                    <EditIcon
                      onClick={() => handleEditPatientData(index)}
                      className={`edit-icon ${isDarkTheme ? "dark" : "light"}`}
                    />
                    <span className="edit-tooltip">Edit</span>
                  </div> */}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {showAddPopup && (
          <AddPatientModal
            setShowAddPopupProp={setShowAddPopup}
            // addNewPatientData={addNewPatient}
          />
        )}
        {/* {showEditPopup && (
          <AddPatientModal
            showEditPopup={showEditPopup}
            setShowEditPopup={setShowEditPopup}
          />
        )} */}
      </TableContainer>
    </Paper>
  );
}

const StyledTableCell = muiStyled(TableCell)(({ theme, isDarkTheme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: isDarkTheme
      ? theme.palette.common.black
      : theme.palette.common.white,
    color: isDarkTheme
      ? theme.palette.common.white
      : theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: isDarkTheme
      ? theme.palette.common.black
      : theme.palette.common.white,
    color: isDarkTheme ? theme.palette.common.white : theme.palette.common.dark,
    borderBottom: "0",
    padding: "9px",
  },
}));

const StyledTableRow = muiStyled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
    color: "black",
    borderBottom: "0",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: "none",
  },
}));
