import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import { device } from "../../shared/BreakPoints";

const Input = ({
  placeholder,
  width,
  height,
  margin,
  marginRight,
  marginLeft,
  type,
  onChange,
  name,
  display,
  marginTop,
  showPassword,
  togglePasswordVisibility,
  visibilityIcon,
  visibilityOffIcon,
}) => {
  return (
    <StyledInput style={{ width: width }}>
      <input
        className="form-control simple_input_field"
        placeholder={placeholder ? placeholder : "Simple Input Field"}
        type={type ? type : "text"}
        onChange={onChange ? onChange : () => false}
        name={name ? name : ""}
        style={{
          height: height ? height : "50px",
          marginLeft: marginLeft ? marginLeft : "",
          marginRight: marginRight ? marginRight : "",
          marginTop: marginTop ? marginTop : "",
          display: display ? display : "",
          padding: "3px 0px 3px 10px",
          border: "none",
          borderBottom: "2px solid #e9e9f0",
          boxShadow: "none",
          borderRadius: "0%",
        }}
        autoComplete="off"
      />
      <Box onClick={togglePasswordVisibility} sx={{ marginLeft: "-2vw" }}>
        {showPassword ? visibilityOffIcon : visibilityIcon}
      </Box>
    </StyledInput>
  );
};

export default Input;

const StyledInput = styled.section`
  display: flex;
  align-items: center;
  input {
    outline: none;
    background-color: transparent !important;
    width: ${(props) => (props.width ? props.width : "100%")};
  }
  input:focus {
    background-color: transparent !important;
  }
  .form-control.simple_input_field:-webkit-autofill {
    background-color: transparent !important;
    box-shadow: 0 0 0px 1000px white inset !important;
  }
  /* Apply margin only on larger screens */
  @media only screen and (min-width: ${device.tablet}) {
    input {
      margin: ${(props) => props.margin};
    }
  }
  /* Apply full width and no margin on small screens */
  @media only screen and (max-width: ${device.laptop}) {
    input {
      width: 100%;
      margin: 0;
    }
  }
`;
