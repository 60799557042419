import React from 'react'
import './chat.css'
import Avatar from "../../../Assets/images/commonImages/Person1.png"
import CircleIcon from '@mui/icons-material/Circle';
import VideocamIcon from '@mui/icons-material/Videocam';
import PhoneIcon from '@mui/icons-material/Phone';
import CloseIcon from "@mui/icons-material/Close"
import LinkIcon from '@mui/icons-material/Link';
import ForwardIcon from '@mui/icons-material/Forward';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ReactScrollToBottom from 'react-scroll-to-bottom'
import Message from '../message/Message'
const Chat = () => {
    return (
        <div className='chatpage'>
            <div className='chatpage__header__container'>
                <div className='chatpage__header__left'>
                    <div className='chatpage__header__left__img'>
                        <img className='chat__person' src={Avatar} alt="img" />
                        <CircleIcon sx={{color: "yellowgreen", position: "absolute", cursor: "pointer", top: "45px", right: "1px"}} />
                    </div>
                    <p>John Mayers</p>
                </div>
                <div className='chatpage__header__right'>
                    <VideocamIcon sx={{color: "black"}}/>
                    <PhoneIcon sx={{color: "black"}} />
                    <CloseIcon sx={{color: "black"}}/>
                </div>
            </div>
            <div className='chat__body__container'>
                <ReactScrollToBottom className="chatBox">
                    <Message user="Mubeen" message="Hi Alison, how can I help you?" classs="left" time="10:24" />
                    <Message message="Hey John, I have a question regarding my acccount. Do you have time for a call?  " classs="right" time="10:24" />
                    <Message user="Mubeen" message="Hi Alison, how can I help you?" classs="left" time="10:24" />
                    <Message message="Hey John, I have a question regarding my acccount. Do you have time for a call? " classs="right" time="10:24" />
                    <Message user="Mubeen" message="Hi Alison, how can I help you?" classs="left" time="10:24" />
                    <Message message="Hey John, I have a question regarding my acccount. Do you have time for a call? " classs="right" time="10:24" />
                    <Message user="Mubeen" message="Hi Alison, how can I help you?" classs="left" time="10:24" />
                    <Message message="Hey John, I have a question regarding my acccount. Do you have time for a call? " classs="right" time="10:24" />
                    <Message user="Mubeen" message="Hi Alison, how can I help you?" classs="left" time="10:24" />
                    <Message message="Hey John, I have a question regarding my acccount. Do you have time for a call? " classs="right" time="10:24" />
                    <Message user="Mubeen" message="Hi Alison, how can I help you?" classs="left" time="10:24" />
                    <Message message="Hey John, I have a question regarding my acccount. Do you have time for a call? " classs="right" time="10:24" />
                </ReactScrollToBottom>
            </div>
            <div className='chat__bottom__container'>
                <div className='chat__bottom__input'>
                    <input placeholder='Type your message here...' type='text' />
                </div>
                <div className='chat__bottom__icon'>
                    <LinkIcon sx={{color: "black"}}/>
                    <ForwardIcon sx={{color: "black"}}/>
                    <EmojiEmotionsIcon sx={{color: "black"}}/>
                </div>
            </div>
        </div>
    )
}

export default Chat