import React from "react";
import { Routes, Route } from "react-router-dom";

import { Layout } from "./Layout";
import LoginPage from "../Authentication/LoginPage/LoginPage";
import SignupPage from "../Authentication/SingupPage/SignupPage";
import ForgotPassword from "../Authentication/EmailRequest/EmailRequest";
import Dashboard from "../Pages/Dashboard/Dashboard";
import PatientOverview from "../Pages/PatientOverview/PatientOverview";
import PatientHistory from "../Pages/PatientHistory/PatientHistory";
import ProfileInfo from "../Pages/ProfileInfo/ProfileInfo";
import AllPatients from "../Pages/AllPatients/AllPatients";
import AnottedChangeNumber from "../Pages/AnottedChangeNumber/teeth";
import AnottedRegionOfInterest from "../Pages/AnottedRegionOfInterest/regionOfInterest";
import AnnottedClick from "../Pages/AnnotatedClick/anottedClick";
import Report from "../Pages/patientReport/ReportBody";
import CBCTReportPage from "../Pages/CBCTReportPage/CBCTReportPage";
import DownloadReport from "../Pages/DownloadReport/DownloadReport";
import Setting from "../Pages/Setting/Settings";
import ShowSlices from "../Pages/ShowSlices/ShowSlices";
import Comments from "../Pages/ChatBox/ChatBox";
import Tasks from "../Pages/Tasks/Tasks";
// import ChartComponent from "../shared/ChartComponent ";
import RequireAuth from "./requireAuth";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="signUp" element={<SignupPage />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route
          path="unauthorized"
          element={
            <div style={{ textAlign: "center", backgroundColor: "grey" }}>
              unauthorized
            </div>
          }
        />
        <Route
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<Dashboard />} />
        </Route>
        <Route
          path="patientOverview"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<PatientOverview />} />
          <Route path="patientHistory" element={<PatientHistory />} />
        </Route>
        <Route
          path="patientHistory"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<PatientHistory />} />
        </Route>
        <Route
          path="profile"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<ProfileInfo />} />
        </Route>
        <Route
          path="allPatients"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<AllPatients />} />
        </Route>
        <Route
          path="anottedChangeNumber"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<AnottedChangeNumber />} />
        </Route>
        <Route
          path="anottedRegionOfInterest"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<AnottedRegionOfInterest />} />
        </Route>
        <Route
          path="anottedClick"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<AnnottedClick />} />
        </Route>
        <Route
          path="report"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<Report />} />
        </Route>
        <Route
          path="cbctReport"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<CBCTReportPage />} />
        </Route>
        <Route
          path="downloadReport"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<DownloadReport />} />
        </Route>
        <Route
          path="showSlices"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<ShowSlices />} />
        </Route>
        <Route
          path="comments"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<Comments />} />
        </Route>
        <Route
          path="tasks"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<Tasks />} />
        </Route>
        <Route
          path="collaborationTools"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<Setting />} />
        </Route>
        <Route
          path="setting"
          element={
            <RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />
          }
        >
          <Route index element={<Setting />} />
        </Route>
        {/* <Route
          path="chart"
          element={<RequireAuth allowedRoles={["Clinician", "SuperAdmin", "Admin"]} />}
        >
          <Route index element={<ChartComponent />} />
        </Route> */}

        <Route path="*" element={<h1>404</h1>} />
      </Route>
    </Routes>
  );
};
