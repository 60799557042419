import React, { Fragment, useContext } from "react";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ThemeContext } from "../../ThemeContext";
function Card(props) {
  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <Fragment>
      <div className={isDarkTheme ? "overview-card-dark bottom-card-dark" : "overview-card-light bottom-card-light"} >
        <div className="cardTitle">
          <h5 className="card-title">{props.title}</h5>
          <AddOutlinedIcon style={{color: "black"}} />
        </div>
        <img src={props.imageUrl} className="card-img-top" alt="..." style={{height: "150px", width: "100%"}} />
        <div className="card-body">
          <p className="card-text">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </p>
        </div>
      </div>
    </Fragment>
  );
}

export default Card;
