import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../ThemeContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HttpRequest } from "../../config/http";
import useAddPatientData from "./useAddPatientData";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Select from "react-select";
import styled from "styled-components";

const MultiSelectOption = ({ innerProps, label, data, isSelected }) => (
  <div {...innerProps}>
    <input
      type="checkbox"
      checked={isSelected}
      onChange={() => null}
      value={data.value}
    />
    {label}
  </div>
);

//Style For Select
const CustomSelect = styled(Select)`
  width: 100%;
`;
const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    backgroundColor: "#f2f2f2",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#f2f2f2",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "#f2f2f2",
  }),
  singleValue: (provided) => ({
    ...provided,
    backgroundColor: "#f2f2f2",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#f2f2f2",
    borderColor: "#808080",
    boxShadow: "0 0 0 0.5px #808080",
    "&:hover": {
      borderColor: "#808080",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#f2f2f2",
      borderColor: "#808080",
      boxShadow: "none",
    },
    "&:hover:focus": {
      backgroundColor: "#f2f2f2",
      borderColor: "#808080",
      boxShadow: "none",
    },
  }),
};
const patientsButtonStyle = {
  backgroundColor: "#1B64C8",
  padding: "0px 10px",
  textTransform: "none",
  fontSize: "15px",
  color: "white",
};
function Popup(props) {
  const { isDarkTheme } = props;
  return (
    <div
      style={{
        position: "fixed",
        zIndex: "9999",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "55%",
          left: "51.9%",
          width: "35vw",
          height: "80vh",
          transform: "translate(-50%, -50%)",
          backgroundColor: isDarkTheme ? "#1E1E20" : "#f2f2f2",
          color: isDarkTheme ? "white" : "black",
          borderRadius: "0.5rem",
          padding: "1rem",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
export default function AddPatientModal({
  setShowAddPopupProp,
  // addNewPatientData,
}) {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    dateOfBirth,
    setdateOfBirth,
    contactNo,
    setContactNo,
    Gender,
    setGender,
    referDoctor,
    setReferDoctor,
  } = useAddPatientData();
  const { isDarkTheme } = useContext(ThemeContext);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [fetchedUsers, setFetchedUsers] = useState([]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const authToken =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMzU3MzI3NzI5ZjNhMGIyZDhkMGMzMSIsImlhdCI6MTY2NDQ0NzI4OH0.huvYjdf_LiRqvN7wBf0pvwRhU5LC3qPlR0YUDI95Mf8";

        const headers = {
          Authorization: `Token ${authToken}`,
        };

        const response = await axios.get(
          "http://localhost:3036/api/v1/user/alluser",
          { headers }
        );
        const users = response.data.data;
        setFetchedUsers(users);
      } catch (error) {
        toast.error("An error occurred while fetching all users data.");
      }
    };

    fetchAllUsers();
  }, []);
  const fetchedDoctorOptions = fetchedUsers.map((user) => ({
    value: user._id,
    label: `${user.name}`,
  }));
  const fetchedDoctorIDs = fetchedUsers.map((user) => {
    return user?._id;
  });
  const handleDoctorSelect = (selectedOptions) => {
    const selectedDoctorNames = selectedOptions.map((option) => option.value);
    const selectedDoctorIDs = fetchedDoctorIDs.filter((id, index) =>
      selectedDoctorNames.includes(fetchedDoctorOptions[index].value)
    );
    setSelectedDoctor(selectedOptions);
    setReferDoctor(selectedDoctorIDs);
    console.log(
      "🚀 ~ file: AddPatientModal.js:167 ~ handleDoctorSelect ~ selectedDoctorIDs:",
      selectedDoctorIDs
    );
  };

  const CreateNewPatientData = async () => {
    var raw = {
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dateOfBirth,
      contactNo: contactNo,
      picture: "null",
      Gender: Gender,
      referDoctor: referDoctor,
    };

    HttpRequest.addPatientApi(raw)
      .then((res) => {
        toast.success("Patient data saved successfully!");
        setFirstName("");
        setLastName("");
        setdateOfBirth("");
        setContactNo("");
        setGender("");
        setReferDoctor([]);
        setSelectedDoctor([]);
      })
      .catch((err) => {
        if (
          firstName === "" ||
          lastName === "" ||
          dateOfBirth === "" ||
          contactNo === "" ||
          Gender === "" ||
          referDoctor.length === 0
        ) {
          toast.error("Please fill all fields.");
        } else {
          toast.error("An error occurred while saving patient data.");
        }
      });
  };

  return (
    <div>
      <Popup isDarkTheme={isDarkTheme}>
        <ToastContainer />
        <div className="add-new-patient">
          <div className="add-new-patient-header">
            <h4 className="create-patient-heading">Create new Patient</h4>
            <CloseIcon
              style={{
                color: isDarkTheme ? "white" : "black",
                cursor: "pointer",
              }}
              onClick={() => setShowAddPopupProp(false)}
            />
          </div>
          <div
            className={
              isDarkTheme
                ? "add-new-patient-body-dark"
                : "add-new-patient-body-light"
            }
          >
            <div
              className={
                isDarkTheme
                  ? "add-new-patient-body-item-dark"
                  : "add-new-patient-body-item-light"
              }
            >
              <input
                type="text"
                name="patientFirstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                required
              />
            </div>
            <div
              className={
                isDarkTheme
                  ? "add-new-patient-body-item-dark"
                  : "add-new-patient-body-item-light"
              }
            >
              <input
                type="text"
                name="patientLastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                required
              />
            </div>

            <div
              className={
                isDarkTheme
                  ? "add-new-patient-body-item-dark"
                  : "add-new-patient-body-item-light"
              }
            >
              <input
                type="date"
                name="patientDOB"
                value={dateOfBirth}
                onChange={(e) => setdateOfBirth(e.target.value)}
                placeholder="Date of Birth"
                required
              />
            </div>
            <div
              className={
                isDarkTheme
                  ? "add-new-patient-body-item-dark"
                  : "add-new-patient-body-item-light"
              }
            >
              <input
                type="tel"
                name="patientContactNo"
                value={contactNo}
                onChange={(e) => setContactNo(e.target.value)}
                placeholder="Contact No"
                required
              />
            </div>
            <div
              className={
                isDarkTheme
                  ? "add-new-patient-body-item-dark"
                  : "add-new-patient-body-item-light"
              }
            >
              <CustomSelect
                value={selectedDoctor}
                onChange={handleDoctorSelect}
                options={[...fetchedDoctorOptions]}
                placeholder="Select Referring Doctors"
                isMulti
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                components={{ Option: MultiSelectOption }}
                styles={customStyles}
              />
            </div>
            <label htmlFor="gender">Gender</label>
            <div>
              <input
                type="radio"
                name="gender"
                value="male"
                checked={Gender === "male"}
                onChange={(e) => setGender(e.target.value)}
              />
              <span>Male</span>
            </div>
            <div>
              <input
                type="radio"
                name="gender"
                value="female"
                checked={Gender === "female"}
                onChange={(e) => setGender(e.target.value)}
              />
              <span>Female</span>
            </div>
          </div>

          <div className="add-new-patient-footer">
            <div
              onClick={() => setShowAddPopupProp(false)}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </div>
            <Button
              type="submit"
              variant="contained"
              style={patientsButtonStyle}
              onClick={CreateNewPatientData}
            >
              <b>Create</b>
            </Button>
          </div>
        </div>
      </Popup>
      {/* <Popup>
          <div className="add-new-patient">
            <div className="add-new-patient-header">
          <h4 className="create-patient-heading">Update Patient</h4>
            <CloseIcon 
          //         style={{
          //           color: isDarkTheme ? "white" : "black",
          //           cursor: "pointer",
          //         }}
          //         onClick={() => setShowEditPopup(false)}
          //       />
          //     </div>
          //     <div
          //       className={
          //         isDarkTheme
          //           ? "add-new-patient-body-dark"
          //           : "add-new-patient-body-light"
          //       }
          //     >
          //       <div
          //         className={
          //           isDarkTheme
          //             ? "add-new-patient-body-item-dark"
          //             : "add-new-patient-body-item-light"
          //         }
          //       >
          //         <input
          //           type="text"
          //           name="patientFirstName"
          //           onChange={handlePatientData}
          //           value={data.fname}
          //           placeholder="First Name"
          //           required
          //         />{" "}
          //       </div>
          //       <div
          //         className={
          //           isDarkTheme
          //             ? "add-new-patient-body-item-dark"
          //             : "add-new-patient-body-item-light"
          //         }
          //       >
          //         <input
          //           type="text"
          //           name="patientLastName"
          //           onChange={handlePatientData}
          //           value={data.lname}
          //           placeholder="Last Name"
          //           required
          //         />
          //       </div>

          //       <div
          //         className={
          //           isDarkTheme
          //             ? "add-new-patient-body-item-dark"
          //             : "add-new-patient-body-item-light"
          //         }
          //       >
          //         <input
          //           type="date"
          //           name="patientDOB"
          //           onChange={handlePatientData}
          //           value={data.dob}
          //           placeholder="Date of Birth"
          //           required
          //         />
          //       </div>
          //       <div
          //         className={
          //           isDarkTheme
          //             ? "add-new-patient-body-item-dark"
          //             : "add-new-patient-body-item-light"
          //         }
          //       >
          //         <input
          //           type="tel"
          //           name="patientContactNo"
          //           value={data.contactNo}
          //           onChange={handlePatientData}
          //           placeholder="Contact No"
          //           required
          //         />
          //       </div>
          //       <div
          //         className={
          //           isDarkTheme
          //             ? "add-new-patient-body-item-dark"
          //             : "add-new-patient-body-item-light"
          //         }
          //       >
          //         <CustomSelect
          //           value={selectedDoctor}
          //           onChange={handlePatientData}
          //           options={doctorsOptions}
          //           placeholder="Select Referring Doctor"
          //           styles={customStyles}
          //         />
          //       </div>
          //       {/* <div
          //         className={
          //           isDarkTheme
          //             ? "add-new-patient-body-item-dark"
          //             : "add-new-patient-body-item-light"
          //         }
          //       >
          //         <input
          //           type="text"
          //           onChange={handlePatientData}
          //             name="patientID"
          //           value={storedPatients.patientID}
          //           placeholder="Patient ID"
          //           required
          //         />
          //       </div> 
          //       <label htmlFor="gender">Gender</label>
          //       <div>
          //         {" "}
          //         <input type="radio" name="gender" value="male" />{" "}
          //         <span>Male</span>
          //       </div>
          //       <div>
          //         {" "}
          //         <input type="radio" name="gender" value="female" />{" "}
          //         <span>Female</span>
          //       </div>
          //     </div>

          //     <div className="add-new-patient-footer">
          //       <div
          //         onClick={() => setShowEditPopup(false)}
          //         style={{ cursor: "pointer" }}
          //       >
          //         Cancel
          //       </div>
          //       <Button
          //         type="submit"
          //         variant="contained"
          //         style={patientsButtonStyle}
          //         // onClick={ha}
          //       >
          //         <b>Update</b>
          //       </Button>
          //     </div>
          //   </div>
            // </Popup>*/}
    </div>
  );
}
