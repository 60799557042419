import React from "react";
import { Button, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import card1 from "./cardPhoto.jpg";
import "./CBCTConclusionCard.css";

const CBCTConclusionCard = ({ isDarkTheme }) => {
  return (
    <>
      <div className={isDarkTheme ? "cbct-study-dark" : "cbct-study-light"}>
        <div className="cbct-study-header">
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h6"
                style={{
                  color: isDarkTheme ? "white" : "black",
                  fontSize: "14px",
                }}
              >
                <strong>CBCT Study, 06/28/2021</strong>
              </Typography>
              <MoreVertIcon style={{ fontSize: "18px" }} />
            </div>
            <p>Sem creates at: 06/28/2021</p>
            <p>Sem UC: 12318632334548656235986238622359895623</p>
          </div>
        </div>
        <div className="cbct-study-body">
          <img src={card1} alt="" />
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              marginTop: "5%",
              width: "25%",
              marginLeft: "75%",
              backgroundColor: "#1B64C8",
            }}
          >
            Order Analysis
          </Button>
        </div>
        <div
          className={
            isDarkTheme ? "cbct-study-footer-dark" : "cbct-study-footer-light"
          }
        >
          <div
            className={
              isDarkTheme
                ? "cbct-study-footer-div-1-dark"
                : "cbct-study-footer-div-1-light"
            }
          >
            {" "}
            <AccountCircleIcon />
            <p className="radiological-report-text">Radiological Report (2)</p>
          </div>
          <div
            className={
              isDarkTheme
                ? "cbct-study-footer-div-2-dark"
                : "cbct-study-footer-div-2-light"
            }
          >
            <p>
              {" "}
              Radiological Report CBCT 46584332{" "}
              <span className="created-date">Created at 06/28/2021</span>
            </p>
            <MoreVertIcon style={{ fontSize: "18px" }} />
          </div>
          <div
            className={
              isDarkTheme
                ? "cbct-study-footer-div-3-dark"
                : "cbct-study-footer-div-3-light"
            }
          >
            <p>
              <Link to="#">
                Click the link on the CBCT study card to open the report.
              </Link>{" "}
              <span className="created-date">Created at 06/28/2021</span>{" "}
            </p>
            <MoreVertIcon style={{ fontSize: "18px" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CBCTConclusionCard;
