import React from "react";
import "./TeethButton.css";

import { Button } from "@mui/material";

const TeethButton = (props) => {
  const svgStyle = {
    height: "25px",
    width: "25px",
  };

  const buttonStyle = {
    backgroundColor: props.backgroundColor,
    minWidth: "0px",
    padding: "3px",
    height: "65px",
    display: "flex",
    flexDirection: "column",
    width: "40px",
    color: props.teethColor,
  };

  return (
    <div className="teeth__buttons__list">
      <Button variant="contained" style={buttonStyle}>
        <svg
          style={svgStyle}
          viewBox="0 0 28 26"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.38722 0H7.00727C10.2727 0.472105 11.158 2.2839 14.0034 2.2839C16.8489 2.2839 17.7342 0.472105 21.0003 0H21.3516C22.2247 0 23.0892 0.168128 23.8959 0.494783C24.7025 0.821439 25.4354 1.30023 26.0527 1.90381C26.6701 2.50739 27.1598 3.22394 27.4939 4.01256C27.828 4.80117 28 5.64641 28 6.5V6.92832C28 8.91458 25.8375 11.1821 25.2007 13.3305C24.5254 15.6116 24.0628 17.4768 23.927 19.4275C23.6086 23.9987 22.0144 26 20.3019 26C17.7447 26 15.4745 15.7539 14.0475 15.7539C12.6199 15.7539 9.78909 26 7.705 26C6.43341 26 4.78672 25.2645 4.16807 19.4275C3.92173 17.1053 3.50603 15.6965 2.80621 13.3305C2.19036 11.2505 0.11327 8.76542 0.00689637 6.53489C-0.0332306 5.69131 0.102066 4.84854 0.404604 4.05754C0.707142 3.26653 1.17063 2.54375 1.76705 1.93288C2.36347 1.32201 3.08042 0.835765 3.87454 0.503539C4.66866 0.171313 5.52345 1.35182e-05 6.38722 0Z"
            fill={props.teethColor}
          />
        </svg>
        <p>{props.teethNumber}</p>
      </Button>
    </div>
  );
};

export default TeethButton;
