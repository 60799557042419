// import React from "react";
import "./dashboard.css";
import React, {useContext} from "react"
import { ThemeContext } from "../../ThemeContext";
import Appointments from "../../Components/Dashboard/Appointments/Appointments";
import Tasks from "../../Components/Dashboard/Tasks/Tasks";
import RecentReports from "../../Components/Dashboard/RecentReports";
import PatientList from "../../Components/Dashboard/PatientList";
import Comments from "../../Components/Dashboard/Comments";
import TopCard from "../../Components/Dashboard/TopCard";
import 'react-circular-progressbar/dist/styles.css';
//Icons
import cardImage1 from "../../Assets/Icons/Tooth.svg";
import cardImage3 from "../../Assets/Icons/Progressbar.png";
import cardImage4 from "../../Assets/Icons/Tooth1.svg";
import cardImage5 from "../../Assets/Icons/textpad.svg";
function Dashboard() {
  const { isDarkTheme} = useContext(ThemeContext)
  return (
    <>
      <div className={isDarkTheme ? "patient-dark" : "patient-light"}>
        <div className={isDarkTheme ? "patient-body-container-dark" : "patient-body-container-light"}>
          <div className="top-cards">
            <TopCard
              imageUrl={cardImage1}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
            <TopCard
              imageUrl={cardImage3}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
            <TopCard
              imageUrl={cardImage1}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
            <TopCard
              imageUrl={cardImage4}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
            <TopCard
              imageUrl={cardImage5}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            />
          </div>
          <div className="main-body">
            <Appointments isDarkTheme={isDarkTheme} />
            <Tasks isDarkTheme={isDarkTheme} />
            <RecentReports isDarkTheme={isDarkTheme} />
          </div>
          <div className="main-body">
            <PatientList />
            <Comments isDarkTheme={isDarkTheme} />
          </div>
        </div>

        {/* ***********************Footer Section***********************  */}
        <footer>
          <div className="list">
            <li>
              <ul>
                <a href="/" style={{ color: "black", marginLeft: "18px" }}>
                  &copy; Periox 2022
                </a>
              </ul>
              <ul>
                <a href="/" style={{ color: "black" }}>
                  support@periox.com {window.name}
                </a>{" "}
              </ul>
              <ul>
                <a href="/">Terms of Service and Privacy</a>{" "}
              </ul>
            </li>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Dashboard;
