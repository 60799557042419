import React from 'react'
import './message.css'
const Message = ({ user, message, classs, time }) => {
    if (user) {
        return (
            <div className={`sent__messageBox ${classs}`}>
                <p className='message__tag'>{message}</p>
                <p className='send__time__tag'>{time}</p>
            </div>
        )
    } else {
        return (
            <div className={`messageBox ${classs}`}>
                <p className='message__tag'>{message}</p>
                <p className='reci__time__tag'>{time}</p>
            </div>
        )
    }
}

export default Message