import React, { useState, useContext } from "react";
import "./Settings.css";
import { ThemeContext } from "../../ThemeContext";
import { Typography } from "@material-ui/core";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StaffTable from "../../Components/CollaborationTools/StaffTable/StaffTable";
import ClinicsDetail from "../../Components/CollaborationTools/organizationInfo/ClinicsDetail";
import { Link } from "react-router-dom";
import AccountInfo from "../../Components/CollaborationTools/accountInfo/AccountInfo";
const Settings = () => {
  const [accountActive, setAccountActive] = useState(true);
  const [organizationActive, setOrganizationActive] = useState(false);
  const [staffActive, setStaffActive] = useState(false);
  const [networkActive, setNetworkActive] = useState(false);

  const handleAccountClick = () => {
    setAccountActive(true);
    setOrganizationActive(false);
    setStaffActive(false);
    setNetworkActive(false);
  };

  const handleOrganizationClick = () => {
    setAccountActive(false);
    setOrganizationActive(true);
    setStaffActive(false);
    setNetworkActive(false);
  };

  const handleStaffClick = () => {
    setAccountActive(false);
    setOrganizationActive(false);
    setStaffActive(true);
    setNetworkActive(false);
  };

  const handleNetworkClick = () => {
    setAccountActive(false);
    setOrganizationActive(false);
    setStaffActive(false);
    setNetworkActive(true);
  };
  const {isDarkTheme} = useContext(ThemeContext)
   return (
    <div className={isDarkTheme? "settings-container-dark" : "settings-container-light"}>
      <div className={isDarkTheme? "settings-header-dark" : "settings-header-light"}>
        <Typography variant="h5">Settings</Typography>
        <Link to='/allPatients'>
          <Button variant="contained" className="settings-header-button">
            <ArrowBackIosIcon style={{ fontSize: "16px" }} />
            Go back to My Patients
          </Button>
        </Link>
      </div>
      <div className="settings-pages">
        <div
          className={`settings-pages-buttons-light-${
            accountActive ? "active" : "deactive"
          }`}
          onClick={handleAccountClick}
        >
          <p>Account</p>
        </div>
        <div
          className={`settings-pages-buttons-light-${
            organizationActive ? "active" : "deactive"
          }`}
          onClick={handleOrganizationClick}
        >
          <p>Organization</p>
        </div>
        <div
          className={`settings-pages-buttons-light-${
            staffActive ? "active" : "deactive"
          }`}
          onClick={handleStaffClick}
        >
          <p>Staff</p>
        </div>
        <div
          className={`settings-pages-buttons-light-${
            networkActive ? "active" : "deactive"
          }`}
          onClick={handleNetworkClick}
        >
          <p>Network</p>
        </div>
      </div>
      {organizationActive ? <ClinicsDetail isDarkTheme={isDarkTheme} /> : accountActive ? <AccountInfo isDarkTheme={isDarkTheme} /> :  <StaffTable isDarkTheme={isDarkTheme} />}
    </div>
  );
};

export default Settings;
