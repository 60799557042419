import React from "react";
import "./emailRequest.css";
import SendEmailButton from "../../BusinessLogistics/Buttons/SimpleButton/SimpleButton";
import Input from "../../BusinessLogistics/InputFields/Input";
import LoginLogo from "../../Assets/images/LoginPage/PerioxaoLogo.png";
import DoctorImg from "../../Assets/images/LoginPage/female-doctor.png";
import styled from "styled-components";
import { device } from "../../shared/BreakPoints";

const EmailRequest = () => {
  return (
    <EmailRequestContainer>
      <div className="email_request_page_container">
        <div className="email_request_page_left">
          <div className="email_request_page_header">
            <img className="mt-2" src={LoginLogo} alt="logo" />
            <p>Enter your email and we send you a password reset link.</p>
          </div>
          <div className="email_request_page_input">
            <Input
              placeholder="Email"
              width="100%"
              margin="4vmax"
              type="email"
            />
            <SendEmailButton
              width="15vmax"
              height="4vmax"
              margin="4vmax 0"
              text="Send Request"
            />
          </div>
          <div className="emailRequest_footer">
            Periox 2022 | support@Periox.ai | Terms of service & policy
          </div>
        </div>
        <div className="email_request_page_right">
          <img className="" src={DoctorImg} alt="" />
        </div>
      </div>
    </EmailRequestContainer>
  );
};

export default EmailRequest;
const EmailRequestContainer = styled.section`
  .email_request_page_container {
    overflow-x: hidden;
    display: flex;
    overflow-y: hidden !important;
    width: 100%;
    height: 100vh;
    background-color: white;
  }
  .email_request_page_left {
    width: 55%;
    padding-top: 2em;
  }
  .email_request_page_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .email_request_page_header > img {
    max-height: 50%;
    max-width: 50%;
  }
  .email_request_page_header > p {
    font-weight: 700;
    font-size: 1.3vmax;
    padding-top: 3em;
    margin-left: 8%;
  }
  .email_request_page_input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5% 20%;
  }
  .emailRequest_footer {
    text-align: center;
    position: absolute;
    margin-top: 15vh;
    margin-left: 25vh;
    color: #858585;
  }
  /* ///////////////////////////////////////////// */
  .email_request_page_right {
    width: 45%;
  }
  .email_request_page_right > img {
    width: 100%;
    height: 100%;
  }

  /* Media Queries */
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: ${device.tabletL}) {
    .email_request_page_left {
      width: 100%;
    }
    .email_request_page_right {
      width: 0;
    }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: ${device.mobileL}) {
  }
`;
