import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { ThemeContext } from '../../../ThemeContext'; 
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { useContext } from 'react';



const rows = [
  {
    patientName : "John Wick",
    patientReportDate : 'Dec 23, 2020',
    patientReportLink: "https://www.google.com/file"
  },
  {
    patientName : "John Wick",
    patientReportDate : 'Dec 23, 2020',
    patientReportLink: "https://www.google.com/file"
  },
  {
    patientName : "John Wick",
    patientReportDate : 'Dec 23, 2020',
    patientReportLink: "https://www.google.com/file"
  },
  {
    patientName : "John Wick",
    patientReportDate : 'Dec 23, 2020',
    patientReportLink: "https://www.google.com/file"
  },
  {
    patientName : "John Wick",
    patientReportDate : 'Dec 23, 2020',
    patientReportLink: "https://www.google.com/file"
  },
  {
    patientName : "John Wick",
    patientReportDate : 'Dec 23, 2020',
    patientReportLink: "https://www.google.com/file"
  },
];

export default function BasicTable() {

  const {isDarkTheme} = useContext(ThemeContext);

  const rowHeadStyleLight = {
    backgroundColor: "#F0F1F6",
    fontWeight: "600",
    color: "#888888",
    height: "50px",
  }
  const rowHeadStyleDark = {
    backgroundColor: "black",
    fontWeight: "600",
    color: "white",
    height: "50px",
  }
  const rowStyleLight = {
    backgroundColor: "#F0F1F6",
    padding: "10px",
    fontWeight: "600"
  }
  const rowStyleDark = {
    backgroundColor: "black",
    color: "white",
    padding: "10px",
    fontWeight: "600"
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow >
            <TableCell align="center" style={isDarkTheme? rowHeadStyleDark : rowHeadStyleLight}>Name</TableCell>
            <TableCell align="center" style={isDarkTheme? rowHeadStyleDark : rowHeadStyleLight}>Date</TableCell>
            <TableCell align="center" style={isDarkTheme? rowHeadStyleDark : rowHeadStyleLight}>Report</TableCell>
            <TableCell align="center" style={isDarkTheme? rowHeadStyleDark : rowHeadStyleLight}>Download Your Report</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.patientName}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center" style={isDarkTheme? rowStyleDark : rowStyleLight}>
                {row.patientName}
              </TableCell>
              <TableCell component="th" scope="row" align="center" style={isDarkTheme? rowStyleDark : rowStyleLight}>
                {row.patientReportDate}
              </TableCell>
              <TableCell align="center" style={isDarkTheme? rowStyleDark : rowStyleLight}>{row.patientReportLink}</TableCell>
              <TableCell align="center" style={isDarkTheme? rowStyleDark : rowStyleLight}><Button variant = "contained" style={{backgroundColor: "#1B64C8", color: "white", padding: "4px 10px", textTransform: "none"}}>Download</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}