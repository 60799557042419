import React, { useState, useContext } from "react";
import { ThemeContext } from "../../ThemeContext";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Switch } from "antd";
import myImage1 from "../../Assets/images/anottedChangeNumber/teeth1.jpg";
import myImage2 from "../../Assets/images/anottedChangeNumber/teeth3.jpg";
import myImage3 from "../../Assets/images/anottedChangeNumber/teeth.jpg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./teeth.css";
import { Link } from "react-router-dom";

function Popup(props) {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: "9999",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "55%",
          left: "51.9%",
          width: "93vw",
          height: "80vh",
          transform: "translate(-50%, -50%)",
          backgroundColor: props.isDarkTheme ? "#1E1E20" : "#f2f2f2",
          color: props.isDarkTheme ? "white" : "black",
          borderRadius: "0.5rem",
          padding: "1rem",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default function Teeth() {
  const [showPopup, setShowPopup] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChange = (checked) => {
    setChecked(checked);
  };

  const { isDarkTheme } = useContext(ThemeContext)
  
  return (  
    <div className={isDarkTheme ? "teeth-dark" : "teeth-light"}>
      <div className={isDarkTheme ? "teeth__navbar-dark" :  "teeth__navbar-light"}>
        <div className={isDarkTheme ? "navbar__left-dark" : "navbar__left-light"}>
          <Link to="/">Link 1</Link>
          <Link to="/">Link 2</Link>
        </div>
        <div className={isDarkTheme ? "navbar__right-dark" : "navbar__right-light"}>
          <Link to="/">Back</Link>

          <Button style={{textTransform : "none"}} onClick={() => setShowPopup(true)} variant="contained">
            <b>
              Upload X-ray
            </b>
          </Button>
          {showPopup && (
            <Popup isDarkTheme={isDarkTheme}>
              <div className={isDarkTheme ? "popup__outer__div-dark" : "popup__outer__div-light"}>
                <div className="popup__header">
                  <div className="heading__and__closeBtn">
                    <h5>Teeth Numbering Validation</h5>
                    <CloseIcon sx={{color: isDarkTheme ? "white" : "black"}} onClick={() => setShowPopup(false)} />
                  </div>
                  <div className="one__by__one__x-ray">
                    <p>AI X-Rays</p>
                    <div className="switch">
                      <Switch
                        defaultChecked
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "ant design" }}
                        style={{
                          backgroundColor: checked ? "#6557D3" : "transparent",
                        }}
                      />
                    </div>
                    <p>One by One</p>
                  </div>
                </div>
                <div className={isDarkTheme ? "teeth__x__rays-dark" : "teeth__x__rays-light"}>
                  <img src={myImage1} alt="teeth" />
                  <img src={myImage1} alt="teeth" />
                  <img src={myImage1} alt="teeth" />
                  <img src={myImage2} alt="teeth" />
                  <img src={myImage2} alt="teeth" />
                  <img src={myImage2} alt="teeth" />
                  <img src={myImage1} alt="teeth" />
                  <img src={myImage1} alt="teeth" />
                  <img
                    src={myImage3}
                    alt="teeth"
                    className="vertical__image1"
                  />
                  <img src={myImage3} alt="teeth" className="vertical__image" />
                  <img
                    src={myImage3}
                    alt="teeth"
                    className="vertical__image3"
                  />
                  <img src={myImage2} alt="teeth" />
                  <img src={myImage2} alt="teeth" />
                </div>
                <div className="popup__footer">
                  <div>AI X-Rays One by One</div>
                  <Button variant="contained">
                    <b>Confirm</b>
                  </Button>
                </div>
              </div>
            </Popup>
          )}
          <Link to="/">
            EN <KeyboardArrowDownIcon />
          </Link>
        </div>
      </div>
      <div className={isDarkTheme ? "teeth__inner-dark" : "teeth__inner-light"}></div>
    </div>
  );
}
