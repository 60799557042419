import React, { useContext } from "react";
import { Navigate, Outlet, useMatch } from "react-router-dom";
import { ThemeContext } from "../ThemeContext";
import styled from "styled-components";
import Sidebarcomponent from "../Components/SideBar/Sidebarcomponent";
import Navbar from "../Components/Header/Navbar";
import { useAuth } from "./../contexts/AuthContext";

export const Layout = () => {
  const { isAuthenticated } = useAuth();

  let login = useMatch("/login");
  let signUp = useMatch("/signUp");
  let anottedRegionOfInterest = useMatch("/anottedRegionOfInterest");
  let anottedClick = useMatch("/anottedClick");
  let report = useMatch("/report");
  let setting = useMatch("/setting");
  let cbctReport = useMatch("/cbctReport");
  let downloadReport = useMatch("/downloadReport");
  let showSlices = useMatch("/showSlices");
  let tasks = useMatch("/tasks");
  let comments = useMatch("/comments");
  let conclusion = useMatch("/conclusion");
  let forgotPassword = useMatch("/forgotPassword");
  let allPatients = useMatch("/allPatients");

  let match = false;

  if (
    login ||
    signUp ||
    anottedRegionOfInterest ||
    anottedClick ||
    report ||
    allPatients ||
    setting ||
    cbctReport ||
    downloadReport ||
    showSlices ||
    tasks ||
    comments ||
    conclusion ||
    forgotPassword
  ) {
    match = true;
  }
  const { isDarkTheme } = useContext(ThemeContext);
  if (isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return (
    <Main>
      <Navbar backgroundColor="white" iconColor="black" />
      <Container match={match}>
        <Sidebarcomponent />
        <OutletSection match={match} isDarkTheme={isDarkTheme}>
          <Outlet />
        </OutletSection>
      </Container>
    </Main>
  );
};
const Main = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const Container = styled.div`
  width: 100vw;
`;

const OutletSection = styled.div`
  width: ${(props) => (props.match ? "100vw" : "calc(100vw - 19vw)")};
  margin-left: ${(props) => (props.match ? "none" : "19vw")};
  margin-top: ${(props) => (props.match ? "none" : "10vh")};
  background-color: ${(props) => (props.isDarkTheme ? "grey" : "#EAECF2")};
`;
