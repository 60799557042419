import React from "react";
import "./reportBody.css";
import profile from "./../../Assets/images/commonImages/Person1.png"
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import ToothButton from "../../Components/ReportComponent/toothButton/ToothButton";
import MuiSwitchIOS from "../../Components/ReportComponent/muiSwitchIOS/MuiSwitchIOS";
import ToothSection from "./ToothSection";

function ReportBody() {
  return (
    <>
      <div className="report-body">
        <div className="main-body">
          <div className="main-body-left">
            <div className="left-body-upper">
              <Typography
                variant="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mb: "25px" }}
                style={{ color: "white" }}
              >
                Radiological Report
              </Typography>
              <p className="date" style={{ color: "white" }}>
                22 April <br /> 2022
              </p>
            </div>
            <div className="left-body-icons">
              <ZoomOutMapOutlinedIcon style={{ color: "white" }} />
              <p style={{ color: "white" }}>3D</p>
              <p style={{ color: "white" }}>Panorma</p>
            </div>
            <div className="left-body-bottom">
              <div className="left-body-bottom-buttons-left">
                <ToothButton toothNumber="11" />
                <ToothButton toothNumber="12" />
                <ToothButton toothNumber="13" />
                <ToothButton toothNumber="14" />
                <ToothButton toothNumber="15" />
                <ToothButton toothNumber="16" />
                <ToothButton toothNumber="17" />
                <ToothButton toothNumber="18" />
              </div>
              <div className="left-body-bottom-buttons-right">
                <ToothButton toothNumber="21" />
                <ToothButton toothNumber="22" />
                <ToothButton toothNumber="23" />
                <ToothButton toothNumber="24" />
                <ToothButton toothNumber="25" />
                <ToothButton toothNumber="26" />
                <ToothButton toothNumber="27" />
                <ToothButton toothNumber="28" />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ToothSection toothNumber="17" />
            <ToothSection toothNumber="16" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportBody;
