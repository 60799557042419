import React from "react";
import myImage1 from "../../Assets/images/anottedRegionOfInterest/combineteeth.png";
import "./data.css";
function data(props) {
  return (
    <>
      <div className={props.isDarkTheme ? "data-main-dark" : "data-main-light"}>
        <table className={props.isDarkTheme ? "teeth__inner__table-dark" : "teeth__inner__table-light"}>
          <tr>
            <td>
              <span>
                Patient name: <b>John Doe</b>
              </span>
            </td>
            <td>
              <span>
                Patient ID: <b>001</b>
              </span>
            </td>
            <td>
              <span>
                Age: <b>25</b>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>
                Date of birth: <b>06/01/1998</b>
              </span>
            </td>
            <td>
              <span>
                Scan date: <b>08/05/2022</b>
              </span>
            </td>
            <td>
              <span>
                Gender: <b>Male</b>
              </span>
            </td>
          </tr>
        </table>
        <img className="teeth__inner__image" src={myImage1} alt="teeth" />
      </div>
    </>
  );
}

export default data;
