import React from "react";
import { Button } from "@mui/material";

import image from "./../../Assets/images/commonImages/background.jpg";
import combineTeeth from "./../../Assets/images/commonImages/background.jpg";

function RecentReports(props) {
  const buttonStyles = {
    minWidth: "0px",
    padding: "0px",
    margin: "3px",
  };
  return (
    <>
      {/* ***********************Third Card***********************  */}

      <div className={props.isDarkTheme ? "recent-report-card-dark main-card" : "recent-report-card-light main-card"}>
        <div className="cardTitle">
          <h5 className="card-title">Recent Reports</h5>
        </div>
        <div className="recentReportsCards">
          <img src={image} className="card-images" alt="" />
          <img src={image} className="card-images" alt="" />
          <img src={image} className="card-images" alt="" />
          <img src={image} className="card-images" alt="" />
          <img src={image} className="card-images" alt="" />
          <img src={image} className="card-images" alt="" />
          <img src={image} className="card-images" alt="" />
          <img src={image} className="card-images" alt="" />
          <img src={image} className="card-images" alt="" />
        </div>

        <a href="/" className="card-link">
          View all
        </a>
      </div>
    </>
  );
}

export default RecentReports;
