import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";

const RequireAuth = ({ allowedRoles }) => {
  const userRoleData = localStorage.getItem("userRole");
  const userRole = JSON.parse(userRoleData);

  console.log("Role to check", userRole);
  const location = useLocation();
  if (!userRoleData) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return allowedRoles.includes(userRole) ? (
    <Outlet />
  ) : userRole ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
// import React from "react";
// import { Outlet, useLocation, Navigate } from "react-router-dom";

// const RequireAuth = ({ allowedRoles }) => {
//   const location = useLocation();

//   const userRoleData = localStorage.getItem("userRole");
//   console.log(
//     "🚀 ~ file: requireAuth.js:31 ~ RequireAuth ~ userRoleData:",
//     userRoleData
//   );

//   // Check if userRoleData is present in localStorage
//   if (!userRoleData) {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }

//   // const userData = JSON.parse(userRoleData);
//   // const role = userData.userData.role;

//   return allowedRoles.includes(userRoleData) ? (
//     <Outlet />
//   ) : (
//     <Navigate to="/unauthorized" state={{ from: location }} replace />
//   );
// };

// export default RequireAuth;
// import React from "react";
// import { Outlet, useLocation, Navigate } from "react-router-dom";

// const RequireAuth = ({ allowedRoles }) => {
//   const location = useLocation();

//   const userRoleData = localStorage.getItem("userRole");

//   // Check if userRoleData is present in localStorage
//   if (!userRoleData) {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }

//   // Check if the user's role is allowed
//   if (allowedRoles.includes(userRoleData)) {
//     return <Outlet />;
//   } else {
//     return <Navigate to="/unauthorized" state={{ from: location }} replace />;
//   }
// };

// export default RequireAuth;
