import React from 'react'
import "./ToothChart.css"
import TeethButton from "./../../shared/teethButton/TeethButton"
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from '@mui/icons-material/Circle';
const ToothChart = (props) => {
  return (
    <>
     <div className={props.isDarkTheme ? "tooth-chart-main-dark" : "tooth-chart-main-light"}>
            <div className="tooth-chart-head">
                <h4>Tooth Chart</h4>
            </div>
            <div className="tooth-chart-body">
            
                <TeethButton teethNumber="01" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="02" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="03" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="04" backgroundColor="rgba(10, 5, 255, 0.2)" teethColor="rgba(10, 5, 255, 0.8)" />
                <TeethButton teethNumber="05" backgroundColor="rgba(10, 5, 255, 0.2)" teethColor="rgba(10, 5, 255, 0.8)" />
                <TeethButton teethNumber="06" backgroundColor="rgba(10, 5, 255, 0.2)" teethColor="rgba(10, 5, 255, 0.8)" />
                <TeethButton teethNumber="07" backgroundColor="rgba(10, 5, 255, 0.2)" teethColor="rgba(10, 5, 255, 0.8)" />
                <TeethButton teethNumber="08" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="09" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="10" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="11" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="12" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="13" backgroundColor="rgba(255, 55, 55, 0.2)" teethColor="rgba(255, 55, 55, 0.8)" />
                <TeethButton teethNumber="14" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="15" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="16" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="17" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="18" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="19" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="20" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="21" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="22" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="23" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="24" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="25" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="26" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="27" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="28" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="29" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="30" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="31" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
                <TeethButton teethNumber="32" backgroundColor="rgba(255, 255, 255, 0.2)" teethColor={props.isDarkTheme ? "rgba(255, 255, 255, 0.8)" : "grey"} />
 
            </div>
            <div className="tooth-chart-foot">
              <div>
              <CircleIcon style={{fontSize: "12px", color: "#D9D9D9"}} /> <span>Healthy</span>
              </div>
              <div>
              <CircleIcon style={{fontSize: "12px", color: "#0A05FF"}} /> <span style={{color: "#0A05FF"}}>Treated</span>
              </div>
              <div>
              <CircleIcon style={{fontSize: "12px", color:"#FF3737"}} /> <span style={{color:"#FF3737"}} >Unhealthy</span>
              </div>
              <div>
              <CloseIcon style={{fontSize: "12px", color: "grey"}} /> <span>Missing</span>
              </div>
            </div>
          </div>
        </>
  )
}
export default ToothChart
