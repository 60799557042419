import React, {useContext} from "react";
import { ThemeContext } from "../../ThemeContext";
import { Button, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import card1 from "./cardPhoto.jpg";
import "./anottedClick.css";
import Navbar2 from ".././../shared/Navbar2/Navbar2";

const AnottedClick = () => {
  const {isDarkTheme} = useContext(ThemeContext);
  return (
    <>
      <div className={isDarkTheme ? "anotted-click-dark" : "anotted-click-light"}>
        <div className="anotted-click-body">
          <div className="annoted-click-cbct">
            <div className={isDarkTheme ? "patient-card-dark" : "patient-card-light"}>
              <div className="patient-card-header">
                <Typography
                  variant="h6"
                  style={{ color: isDarkTheme? "white" : "black", padding: "3% 5% 3% 5%" }}
                >
                  <strong>Patient Card</strong>
                </Typography>
              </div>
              <div className="patient-card-body">
                <div className="patient-card-body-left">
                  <p>Patient ID</p>
                  <p>Gender </p>
                  <p>Date of Birth </p>
                  <p>Age </p>
                  <p>Doctor </p>
                </div>
                <div className="patient-card-body-right">
                  <p>C01</p>
                  <p>Female</p>
                  <p>06/18/1978 </p>
                  <p>45</p>
                  <p>Art Gr.</p>
                </div>
              </div>
              <div className="patient-card-footer">
                <Button
                  variant="contained"
                  style={{
                    minWidth: "0px",
                    textTransform: "none",
                    fontSize: "12px",
                    backgroundColor: "rgb(27, 100, 200)",
                    width: "80px",
                    height: "30px"
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  style={{
                    minWidth: "0px",
                    textTransform: "none",
                    fontSize: "12px",
                    backgroundColor: "rgb(27, 100, 200)",
                    width: "80px",
                    height: "30px"
                  }}
                >
                  Share
                </Button>
              </div>
            </div>
            <div className="annotated__sidebar">
              <p>Study Type</p>
              <div className={isDarkTheme ? "sidebar-item-cbct-dark" : "sidebar-item-cbct-light"}>
                <p>
                  <Link to="/">CBCT (1)</Link>
                </p>
                <AddIcon style={{backgroundColor: "white", color: "purple", borderRadius: "100%", fontSize: "16px", marginTop: "5px"}} />
              </div>
              <div className={isDarkTheme ? "sidebar-item-dark" : "sidebar-item-light"} >
                <Link to="/">Intercol</Link>
              </div>
              <div className={isDarkTheme ? "sidebar-item-dark" : "sidebar-item-light"}>
                <Link to="/">Panorma</Link>
              </div>
              <div className={isDarkTheme ? "sidebar-item-dark" : "sidebar-item-light"}>
                <Link to="/">Photos (7) beta</Link>
              </div>
            </div>
          </div>

          <div className={isDarkTheme? "cbct-study-dark" : "cbct-study-light"}>
            <div className="cbct-study-header">
              <div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                <Typography
                  variant="h6"
                  style={{  color: isDarkTheme? "white" : "black", fontSize: "14px" }}
                >
                  <strong>CBCT Study, 06/28/2021</strong>
                </Typography>
                <MoreVertIcon style={{fontSize: "18px"}}/>
                </div>
                <p>Sem creates at: 06/28/2021</p>
                <p>Sem UC: 12318632334548656235986238622359895623</p>
              </div>
            </div>
            <div className="cbct-study-body">
              <img src={card1} alt="" />
              <Button
                variant="contained"
                style={{
                  minWidth: "0px",
                  textTransform: "none",
                  fontSize: "12px",
                  margin: "13px 0 0 495px",
                  backgroundColor: "#685BE8ff",
                }}
              >
                Order Analysis
              </Button>
            </div>
            <div className={isDarkTheme ? "cbct-study-footer-dark" : "cbct-study-footer-light"}>
              <div className={isDarkTheme ? "cbct-study-footer-div-1-dark" : "cbct-study-footer-div-1-light"}>
                {" "}
                <AccountCircleIcon />
                <p className="radiological-report-text">
                  Radiological Report (2)
                </p>
              </div>
              <div className={isDarkTheme ? "cbct-study-footer-div-2-dark" : "cbct-study-footer-div-2-light"}>
                <p>
                  {" "}
                  Radiological Report CBCT 46584332{" "}
                  <span className="created-date">Created at 06/28/2021</span>
                </p>
                <MoreVertIcon style={{fontSize: "18px"}} />
              </div>
              <div className={isDarkTheme ? "cbct-study-footer-div-3-dark" : "cbct-study-footer-div-3-light"}>
                <p>
                  <Link to="#">
                    Click the link on the CBCT study card to open the report.
                  </Link>{" "}
                  <span className="created-date">Created at 06/28/2021</span>{" "}
                </p>
                <MoreVertIcon style={{fontSize: "18px"}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AnottedClick;
