import React from 'react'
import './DownloadReportPdf.css'
import { CheckBox } from '@mui/icons-material'
import { Button } from '@mui/material'

const DownloadReportPdf = (props) => {
    const buttonStyle = {
        minWidth: "none",
        textTransform: "none",
        padding: "3px 5px",
    }
    const checkboxStyle ={
        height: "20px",
        width: "20px" 
    }
  return (
    <div className={props.isDarkTheme ? 'pdf-download-body-dark': 'pdf-download-body-light'}>
        <p>Download version report</p>
        <div className="checkbox-section">
            <input type="checkbox" name="" id="inverted" style={checkboxStyle} /> <label htmlFor="inverted">Inverted</label>
        </div>
        <div className="checkbox-section">
            <input type="checkbox" name="" id="probabilities" /> <label htmlFor="inverted">Probabilities</label>
        </div>
        <div className="checkbox-section">
            <input type="checkbox" name="" id="dental-cart" /> <label htmlFor="dental-cart">Dental Cart</label>
        </div>
        <div className="checkbox-section">
            <input type="checkbox" name="" id="show-upper-jaw" /> <label htmlFor="show-upper-jaw">Show Upper Jaw</label>
        </div>
        <div className="checkbox-section">
            <input type="checkbox" name="" id="show-lower-jaw" /> <label htmlFor="show-lower-jaw">Show Lower Jaw</label>
        </div>
        <div className="checkbox-section">
            <input type="checkbox" name="" id="show-diagnosis" /> <label htmlFor="show-diagnosis">Show Diagnosis</label>
        </div>
        <div className="checkbox-section">
            <input type="checkbox" name="" id="show-slices" /> <label htmlFor="show-slices">Show Slices</label>
        </div>
        <div className="checkbox-section">
            <input type="checkbox" name="" id="highlight-detectors" /> <label htmlFor="highlight-detectors">Highlight Detecters</label>
        </div>
        <div className="checkbox-section">
            <input type="checkbox" name="" id="referral-recommendations" /> <label htmlFor="referral-recommendations">Refferral Recommendations</label>
        </div>
        <p>Select Print Type</p>
        <div className="checkbox-section">
            <input type="checkbox" name="" id="color-print" /> <label htmlFor="highlight-detectors">Color</label>
        </div>
        <div className="checkbox-section">
            <input type="checkbox" name="" id="blackwhite-print" /> <label htmlFor="referral-recommendations">Black & White</label>
        </div>
        <p>Not signed by doctor</p>
        <div className="download-report-pdf-footer">
            <Button variant="contained" style={buttonStyle}>Download Pdf</Button>
            <Button variant="contained" style={buttonStyle}>Print</Button>
        </div>
    </div>
  )
}

export default DownloadReportPdf
