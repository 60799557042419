import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { signupApi } from "../../config/http";
import DoctorImg from "../../Assets/images/LoginPage/female-doctor.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoginLogo from "../../Assets/images/LoginPage/PerioxaoLogo.png";
import Input from "../../BusinessLogistics/InputFields/Input";
import SingupButton from "../../BusinessLogistics/Buttons/SimpleButton/SimpleButton";
import { device } from "../../shared/BreakPoints";
import { colors } from "../../shared/colors";
import Select from "react-select";
//Style For Select
const CustomSelect = styled(Select)`
  width: 100%;
`;
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "#808080",
    boxShadow: "0 0 0 0.5px #808080",
    marginTop: "10px",
    width: "100%",

    "&:hover": {
      borderColor: "#808080",
      boxShadow: "none",
    },
    "&:focus": {
      // backgroundColor: "#f2f2f2",
      borderColor: "#808080",
      boxShadow: "none",
    },
    "&:hover:focus": {
      // backgroundColor: "#f2f2f2",
      borderColor: "#808080",
      boxShadow: "none",
    },
  }),
};
const SingupPage = () => {
  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    gender: "",
    dob: "",
    addressUse: "",
    addressType: "",
    streetName: "",
    city: "",
    district: "",
    state: "",
    postelCode: "",
    country: "",
    specialty: "",
  });
  // const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const onChangeHandler = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };
  const SpecialtyOptions = [
    { value: "neurologist", label: "Neurologist" },
    { value: "radiologist", label: "Radiologist" },
    { value: "other", label: "Other" },
  ];

  const onRegisterHandler = async (e) => {
    e.preventDefault();

    if (
      registerData.email === "" ||
      registerData.password === "" ||
      registerData.firstName === "" ||
      registerData.lastName === "" ||
      registerData.phone === "" ||
      registerData.gender === "" ||
      registerData.dob === "" ||
      registerData.addressUse === "" ||
      registerData.addressType === "" ||
      registerData.streetName === "" ||
      registerData.city === "" ||
      registerData.district === "" ||
      registerData.state === "" ||
      registerData.postelCode === "" ||
      registerData.country === "" ||
      registerData.specialty === null
    ) {
      toast.error("Please fill all the input fields properly!", "error");
    } else {
      const signupResult = await signupApi(
        registerData.firstName,
        registerData.lastName,
        registerData.email,
        registerData.password,
        registerData.phone,
        registerData.gender,
        registerData.dob,
        registerData.addressUse,
        registerData.addressType,
        registerData.streetName,
        registerData.city,
        registerData.district,
        registerData.state,
        registerData.postelCode,
        registerData.country,
        selectedSpecialty.value
      );
      if (signupResult.success) {
        toast.success("User created Successfully.");
        setRegisterData({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          phone: "",
          gender: "",
          dob: "",
          addressUse: "",
          addressType: "",
          streetName: "",
          city: "",
          district: "",
          state: "",
          postelCode: "",
          country: "",
          specialty: "",
        });
        setSelectedSpecialty(null);
      } else {
        console.log("User already exists or sign-up failed");
        toast.error(signupResult.error);
      }
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <SignUpPageContainer>
        <ToastContainer />
        <div className="signup_header_wrapper">
          <img className="mt-1" src={LoginLogo} alt="logo" />
          <p>Please complete to create your account.</p>
        </div>
        <div className="singup_page_body">
          <div className="singup_page_left">
            <div className="signup_left_two_input">
              <Input
                placeholder="First Name"
                name="firstName"
                onChange={(e) => onChangeHandler(e)}
                value={registerData.firstName}
                width="30%"
              />
              <Input
                placeholder="Last name"
                name="lastName"
                onChange={(e) => onChangeHandler(e)}
                value={registerData.lastName}
                width="30%"
              />
            </div>
            <div className="signup_input_fields">
              <Input
                placeholder="Email"
                type="email"
                value={registerData.email}
                name="email"
                onChange={(e) => onChangeHandler(e)}
                width="100%"
              />
              <Input
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                width="100%"
                value={registerData.password}
                name="password"
                onChange={(e) => onChangeHandler(e)}
                showPassword={showPassword}
                togglePasswordVisibility={() =>
                  setShowPassword((prevShowPassword) => !prevShowPassword)
                }
                visibilityIcon={
                  <VisibilityIcon
                    sx={{
                      cursor: "pointer",
                      color: "black",
                      "&:hover": {
                        background: "transparent",
                        boxShadow: "none",
                      },
                    }}
                  />
                }
                visibilityOffIcon={
                  <VisibilityOffIcon
                    sx={{
                      cursor: "pointer",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      },
                    }}
                  />
                }
              />
              <Input
                placeholder="Phone"
                type="text"
                name="phone"
                value={registerData.phone}
                onChange={(e) => onChangeHandler(e)}
                width="100%"
              />
              <Input
                placeholder="Date of Birth"
                type="date"
                value={registerData.dob}
                name="dob"
                onChange={onChangeHandler}
                width="100%"
              />
              <Input
                placeholder="Address"
                type="text"
                value={registerData.addressUse}
                name="addressUse"
                onChange={onChangeHandler}
                width="100%"
              />
            </div>
          </div>
          <div className="singup_page_right">
            <div className="signup_left_two_input">
              <Input
                placeholder="Address Type"
                type="text"
                value={registerData.addressType}
                name="addressType"
                onChange={onChangeHandler}
                width="40%"
              />
              <Input
                placeholder="Street Name"
                type="text"
                value={registerData.streetName}
                name="streetName"
                onChange={onChangeHandler}
                width="40%"
              />
            </div>
            <div className="signup_left_two_input">
              <Input
                placeholder="City"
                type="text"
                value={registerData.city}
                name="city"
                onChange={onChangeHandler}
                width="40%"
              />
              <Input
                placeholder="District"
                type="text"
                value={registerData.district}
                name="district"
                onChange={onChangeHandler}
                width="40%"
              />
            </div>
            <div className="signup_left_two_input">
              <Input
                placeholder="State"
                type="text"
                value={registerData.state}
                name="state"
                onChange={onChangeHandler}
                width="40%"
              />
              <Input
                placeholder="Postal Code"
                type="text"
                value={registerData.postelCode}
                name="postelCode"
                onChange={onChangeHandler}
                width="40%"
              />
            </div>
            <div className="signup_left_two_input">
              <div className="signup_gender">
                <label className="gender_label">Gender:</label>
                <div>
                  <label className="gender_label_male">
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={registerData.gender === "male"}
                      onChange={onChangeHandler}
                    />
                    Male
                  </label>
                  <label className="gender_label_female">
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={registerData.gender === "female"}
                      onChange={onChangeHandler}
                    />
                    Female
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="gender"
                      value="other"
                      checked={registerData.gender === "other"}
                      onChange={onChangeHandler}
                    />
                    Other
                  </label>
                </div>
              </div>

              <Input
                placeholder="Country"
                type="text"
                value={registerData.country}
                name="country"
                onChange={onChangeHandler}
                width="40%"
              />
            </div>
            <div className="select">
              <CustomSelect
                options={SpecialtyOptions}
                value={selectedSpecialty}
                onChange={(selectedOption) =>
                  setSelectedSpecialty(selectedOption)
                }
                placeholder="Select Specialty"
                styles={customStyles}
              />
            </div>
          </div>
        </div>
        <div className="singupButton_and_footer">
          <div class="form-check singup_check_box">
            <input
              class=" form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              style={{ zoom: 1.5 }}
            />
            <label class="signup_label form-check-label" for="flexCheckDefault">
              I agree with terms and conditions
            </label>
          </div>
          <div className="singup_page_button">
            <SingupButton
              width="15vmax"
              onClick={onRegisterHandler}
              height="4vmax"
              margin="1vmax"
              text="Sign Up"
            />
          </div>
          <div className="singup_page_a_tag">
            <a href="/login">
              Already have an account? <span>Sign in</span>.
            </a>
          </div>
          <div className="signup_footer">
            Periox 2022 | support@Periox.ai | Terms of service & policy
          </div>
        </div>
      </SignUpPageContainer>
    </>
  );
};

export default SingupPage;
const SignUpPageContainer = styled.section`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  align-items: center;
  justify-content: space-between;

  overflow-y: hidden;
  min-height: 100vh;
  background-color: white;
  width: 100%;
  margin-top: 0;
  .signup_header_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
  }
  .signup_header_wrapper > P {
    font-weight: 600;
    font-size: 1.4vmax;
    margin-top: 1vmax;
  }
  .signup_header_wrapper > img {
    max-height: 35%;
    max-width: 35%;
  }
  .singup_page_body {
    display: flex;
    justify-content: center;
  }
  .singup_page_left {
    display: flex;
    flex-direction: column;
    width: 50vw;
    background-color: white;
  }
  .signup_left_header_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signup_left_two_input {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0% 13% 0 13%;
  }
  .signup_input_fields {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 2% 20% 2% 20%;
  }

  .singup_page_a_tag {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .singup_page_a_tag > a {
    text-decoration: none;
    color: #b1b1b1;
    font-weight: 600;
    font-size: 1vmax;
    span {
      color: ${colors.blue};
    }
  }

  /* //////////////////////////////////////////////////////////////////// */
  .singup_page_right {
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .signup_gender {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 5px;
      .gender_label_male {
        margin-right: 10px;
      }
      .gender_label_female {
        margin-right: 10px;
      }
    }
    .select {
      width: 55%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .singupButton_and_footer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    .singup_check_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .singup_page_button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .signup_label {
      color: #b1b1b1;
      font-weight: 600;
      font-size: 1vmax;
      margin-left: 0.5%;
    }
    .signup_footer {
      text-align: center;
      margin-top: 1vh;
      color: #858585;
    }
  }

  // @media only screen and (max-width: ${device.tabletL}) {
  //   .singup_page_left {
  //     width: 100%;
  //   }
  //   .singup_check_box {
  //     margin-left: 17vmax;
  //   }
  //   .singup_check_box {
  //     margin-left: 5%;
  //   }
  //   .signup_button {
  //     margin-x: 0;
  //   }
  //   .signup_input_fields {
  //     margin: 5%;
  //   }

  //   .signup_footer {
  //     margin-top: 3%;
  //   }
  //   .singup_page_a_tag > a {
  //     font-size: 1.4vmax;
  // }
  // .signup_left_two_input {
  //   margin: 0;
  // }
  // @media only screen and (max-width: ${device.mobileL}) {
  //   .signup_left_input_fields {
  //     margin: 0;
  //   }
  //   .singup_check_box {
  //     margin-left: 5%;
  //   }
  //   .signup_footer {
  //     margin-top: 1vh;
  //     }
  // }
`;
