import React, {useContext} from "react";
import Header from "./Header/Header";
import Body from "./Body/Body";
import "./patientOverview.css";
import { ThemeContext } from "../../ThemeContext";
function Ahmad() {
  
  const { isDarkTheme } = useContext(ThemeContext);

  return (
    <div className={isDarkTheme ? "patient-overview-body-dark" : "patient-overview-body-light"}>
      <Header />
      <Body />
    </div>
  );
}

export default Ahmad;
