import React, { useContext } from "react";
import styled from "styled-components";
import { NavLink, useMatch } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeContext } from "../../ThemeContext";
import { device } from "../../shared/BreakPoints";

//Icons
import { ReactComponent as PatientOverviewSvg } from "../../Assets/Icons/allPatients.svg";
import { ReactComponent as AnnotatedClickSvg } from "../../Assets/Icons/annotatedClick.svg";
import { ReactComponent as CollaborationToolSvg } from "../../Assets/Icons/collaborationTools.svg";
import { ReactComponent as NotificationSvg } from "../../Assets/Icons/notification.svg";
import { ReactComponent as LogOutSvg } from "../../Assets/Icons/logOut.svg";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useAuth } from "./../../contexts/AuthContext";

function Sidebarcomponent({ selected }) {
  const { logout } = useAuth();

  const { isDarkTheme } = useContext(ThemeContext);
  let sidebarHistory = null;

  let dashboard = useMatch("/");
  let patientOverview = useMatch("/patientOverview");
  let patientHistory = useMatch("/patientHistory");
  let profile = useMatch("/profile");
  let anottedChangeNumber = useMatch("/anottedChangeNumber");
  let anottedClick = useMatch("/anottedClick");

  let collaborationTools = useMatch("/collaborationTools");

  if (
    dashboard ||
    patientOverview ||
    patientHistory ||
    profile ||
    anottedChangeNumber ||
    anottedClick ||
    collaborationTools
  ) {
    sidebarHistory = true;
  }

  const handleLogout = () => {
    logout();
    localStorage.clear();
    toast.success("User logged out");
  };

  return (
    <SideBarContainer props={sidebarHistory} isDarkTheme={isDarkTheme}>
      <h6 className="dashboard">Dashboard</h6>
      <NavLink to="/patientOverview">
        <div className="sidebar_options">
          <PatientOverviewSvg className="icon" />
          <h6 className="nav_items">Patient Overview</h6>
        </div>
      </NavLink>
      <NavLink to="/PatientHistory">
        <div className="sidebar_options">
          <ClockCircleOutlined className="icon" />
          <h6 className="nav_items">Patient History</h6>
        </div>
      </NavLink>
      <NavLink to="/anottedClick">
        <div className="sidebar_options">
          <AnnotatedClickSvg className="icon" />
          <h6 className="nav_items">Annotated Click</h6>
        </div>
      </NavLink>
      <NavLink to="/collaborationtools">
        <div className="sidebar_options">
          <CollaborationToolSvg />
          <h6 className="nav_items">Collaboration Tools</h6>
        </div>
      </NavLink>
      <NavLink to="downloadReport">
        <div className="sidebar_options">
          <CollaborationToolSvg className="icon" />
          <h6 className="nav_items">Download Report</h6>
        </div>
      </NavLink>
      <NavLink to="showSlices">
        <div className="sidebar_options">
          <CollaborationToolSvg className="icon" />
          <h6 className="nav_items">Show Slices</h6>
        </div>
      </NavLink>
      <NavLink to="anottedRegionOfInterest">
        <div className="sidebar_options">
          <CollaborationToolSvg className="icon" />
          <h6 className="nav_items">Annoted Region of Interest</h6>
        </div>
      </NavLink>
      <NavLink to="anottedChangeNumber">
        <div className="sidebar_options">
          <CollaborationToolSvg className="icon" />
          <h6 className="nav_items">Annoted Change Number</h6>
        </div>
      </NavLink>
      <div className="others_section">
        <h6 className="others">Others</h6>
        <NavLink to="/AppointmentBookingView">
          <div className="sidebar_options">
            <NotificationSvg className="icon" />
            <h6 className="nav_items">Notifications</h6>
          </div>
        </NavLink>
        <NavLink to="/login">
          <div className="sidebar_options" onClick={handleLogout}>
            <LogOutSvg />
            <h6 className="nav_items">Log Out</h6>
          </div>
        </NavLink>
      </div>
    </SideBarContainer>
  );
}
export default Sidebarcomponent;
const SideBarContainer = styled.section`
  ${({ isDarkTheme, props }) => `
  display: ${props ? "flex" : "none"};
    height: calc(100vh - 10vh);
    scroll-behavior: smooth;
    scrollbar-width: none;
    scroll-snap-type: mandatory;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    flex-direction: column;
    background-color: ${isDarkTheme ? "black" : "white"};
    color: ${isDarkTheme ? "white" : "black"};
    padding-top: 2vh;
    margin-top: 10vh;
    position: fixed;
    width: 19vw;
    .nav_items{
      font-family: "Poppins", sans-serif;
      font-size: 1vw;
      margin-left: 0.6em;
      color: ${isDarkTheme ? "white" : "#717376"};
    }
    .active .nav_items {
      color: ${isDarkTheme ? "white" : "#1B64C8"};
    }
    .others_section{
      margin-top: 2vh;
    }
    .dashboard{
      font-size: 1vw;
      margin-left: 2.1vw;
      margin-bottom: 0.3vw;
      color: #999999;
    }
    .others{
      font-size: 1vw;
      margin-left: 2.1vw;
      margin-bottom: 0.3vw;
      color: #999999;
    }
   
    .active {
      color: ${isDarkTheme ? "white" : "#685BE8ff"};
      border-left: 0.3em solid #1B64C8;
    }
    .icon{
      font-size: 22px;
      color: #999999;
    }
    .active .icon{
      color: #1B64C8;
    }
    .sidebar_options {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 0.5em;
      margin: 0 1vw;
      padding: 2vh 1.2vw;
      border-radius: 5px;
      h6 {
        font-family: "Poppins", sans-serif;
      }
    }
    .active .sidebar_options {
      background-color: ${isDarkTheme ? "#685BE8ff" : "#EAECF2"};
      padding: 2vh 0.9vw;
    }
      /* Media Query */
      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (max-width: ${device.tabletL}) {
        .nav_items{
          display: none;
        }
        .dashboard{
          font-size: 2vw;
          margin-left: 3.1vw;
        }
        .others{
          font-size: 2vw;
        }
        .sidebar_options {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (max-width: ${device.mobileL}) {
    
      }
`};
`;
