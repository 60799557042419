import React, { useState } from "react";
import { loginApi } from "../../config/http";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DoctorImg from "../../Assets/images/LoginPage/female-doctor.png";
import LoginLogo from "./../../Assets/images/LoginPage/PerioxaoLogo.png";
import Input from "../../BusinessLogistics/InputFields/Input";
import LoginButton from "../../BusinessLogistics/Buttons/SimpleButton/SimpleButton";
import SignupButton from "../../BusinessLogistics/Buttons/HoverButton/HoverButton";
import { Link } from "react-router-dom";
import { device } from "../../shared/BreakPoints";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import { useAuth } from "./../../contexts/AuthContext";

function LoginPage() {
  // const { login } = useAuth();

  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const onChangeHandler = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const onLoginHandler = async (e) => {
    e.preventDefault();
    if (loginData.email === "" || loginData.password === "") {
      toast.error("Please fill all the input fields properly!", "error");
    } else {
      try {
        const response = await loginApi(loginData.email, loginData.password);
        console.log("this is response in login", response);
        setLoginData({
          email: "",
          password: "",
        });

        console.log("status from login", response.status);

        if (response.success) {
          localStorage.setItem("token", response.userData.token);
          toast.success("Login Successful.", { autoClose: 2000 });
          setTimeout(() => {
            navigate("/");
          }, 2000);
          // login();
          // localStorage.setItem("role", "Clinician");
        } else if (response.status === 400) {
          toast.error(response.message);
        } else {
          alert("Try again");
        }
      } catch (error) {
        console.error("Error during login:", error.message);
        toast.error("Invalid Credentials");
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
      }
    }
  };
  const [remind, setRemind] = useState(false);

  return (
    <StyledContainer>
      <div className="login_page_left">
        <ToastContainer />
        <div className="login_left_header_wrapper">
          <img className="mt-2" src={LoginLogo} alt="logo" />
          <p>Welcome back! Please login to your account.</p>
        </div>
        <form>
          <div className="login_left_input_fields">
            <Input
              placeholder="Username"
              width="100%"
              name="email"
              marginTop="5%"
              value={loginData.email}
              onChange={(e) => onChangeHandler(e)}
            />
            <Input
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              width="100%"
              value={loginData.password}
              name="password"
              onChange={(e) => onChangeHandler(e)}
              showPassword={showPassword}
              togglePasswordVisibility={() =>
                setShowPassword((prevShowPassword) => !prevShowPassword)
              }
              visibilityIcon={
                <VisibilityIcon
                  sx={{
                    cursor: "pointer",
                    color: "black",
                    "&:hover": {
                      background: "transparent",
                      boxShadow: "none",
                    },
                  }}
                />
              }
              visibilityOffIcon={
                <VisibilityOffIcon
                  sx={{
                    cursor: "pointer",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  }}
                />
              }
            />
          </div>
          <div className="login_check_btn">
            <div className="form-check login_check_box">
              <input
                className="form-check-input"
                onChange={() => setRemind(!remind)}
                type="checkbox"
                value=""
                id="flexCheckDefault"
                style={{ zoom: 1.2 }}
              />
              <label
                className="login_label form-check-label"
                htmlFor="flexCheckDefault"
              >
                Remember Me
              </label>
            </div>
            <Link to="/forgotPassword">Forgot Password?</Link>
          </div>
          <div className="login_button">
            <div className="left">
              <Link to="/">
                <LoginButton
                  width="100%"
                  height="4vmax"
                  text="Login"
                  onClick={onLoginHandler}
                  disabled={!remind}
                />
              </Link>
            </div>
            <div className="right">
              <Link to="/signUp">
                <SignupButton width="100%" height="4vmax" text="Sign Up" />
              </Link>
            </div>
          </div>
        </form>
        <div className="login_footer">
          Periox 2022 | support@Periox.ai | Terms of service & policy
        </div>
      </div>
      <div className="login_page_right">
        <img className="" src={DoctorImg} alt="" />
      </div>
    </StyledContainer>
  );
}

export default LoginPage;

const StyledContainer = styled.section`
  display: flex;
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  .login_page_left {
    display: flex;
    flex-direction: column;
    width: 55vw;
    padding: 3em 3em 0 3em;
    background-color: white;
  }
  .login_left_header_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .login_left_header_wrapper > img {
    max-height: 50%;
    max-width: 50%;
  }

  .login_left_header_wrapper > p {
    font-weight: 900;
    font-size: calc(3px + 0.0001vmin + 1.4vmax);
    margin-top: calc(10px + 1.4vmin + 1.4vmax);
  }

  .login_left_input_fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10%;
  }

  .login_check_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2% 10%;
  }

  .login_check_box {
    display: flex;
    align-items: center;
    gap: 2%;
    box-shadow: none;
    outline: none;
    width: 50%;
  }

  .login_label {
    color: #b1b1b1;
    font-weight: 600;
    font-size: calc(10px + 0.3vmin + 0.3vmax);
  }

  .login_check_btn > a {
    text-decoration: none;
    color: #b1b1b1;
    font-weight: 600;
    font-size: calc(10px + 0.3vmin + 0.3vmax);
  }
  .login_check_btn > a:hover {
    color: #b1b1b1;
  }

  .login_button {
    width: 100%
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10% 10% 0 10%;
    .left {
      width: 30%;
    }
    .right {
      width: 30%;
    }
  }
.login_footer{
  text-align: center;
  position: absolute;
  margin-top: 87vh;
  margin-left: 25vh;
  color: #858585;
}
  /* /////////////////////////////////////////////// */
  .login_page_right {
    width: 45vw;
  }

  .login_page_right > img {
    width: 100%;
    height: 100vh;
  }

  .login_check_box > input {
    outline: none;
    box-shadow: none;
  }

  .login_check_box > input:hover {
    outline: none;
    box-shadow: none;
  }

  /* Media Queries */
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: ${device.tabletL}) {
    .login_page_left {
      width: 100%;
    }
    .login_page_right {
      display: none;
    }
    .login_check_btn {
      margin-x: 0;
    }
    .login_button {
      margin-x: 0;
    }
    .login_left_input_fields {
      margin-x: 10%;
    }
    .login_check_btn {
      margin: 4% 10%;
    }
    .login_footer{
      left: 20%;
    }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: ${device.mobileL}) {
    .login_left_input_fields {
      margin: 0;
    }
    .login_check_btn {
      margin: 4% 0;
    }
    .login_footer{
      left: 0;
      padding: 0 10%;
    }
  }
`;
