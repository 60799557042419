import { Typography, Button } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../../../ThemeContext";
import "./ClinicsDetail.css";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

function Popup(props) {
  const { isDarkTheme } = props;
  console.log(isDarkTheme);
  return (
    <div
      style={{
        position: "fixed",
        zIndex: "9999",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "55%",
          left: "51.9%",
          width: "40vw",
          height: "87vh",
          transform: "translate(-50%, -50%)",
          backgroundColor: isDarkTheme ? "#1E1E20" : "#f2f2f2",
          color: isDarkTheme ? "white" : "black",
          borderRadius: "0.5rem",
          padding: "1rem",
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkitScrollbar": {
            display: "none",
          },
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

const ClinicsDetail = (props) => {
  const { isDarkTheme } = useContext(ThemeContext);
  const [showEditClinicPopup, setEditClinicShowPopup] = useState(false);
  const [showClinicPopup, setClinicShowPopup] = useState(false);
  const [storeData, setStoreData] = useState([]);

  const [organizationData, setOrganizationData] = useState({
    organizationName: "",
    organizationCity: "",
    organizationAddress: "",
    organizationEmail: "",
    organizationPhone: "",
    organizationWebsite: "",
  });

  const [editData, setEditData] = useState(null);

  useEffect(() => {}, [storeData, organizationData]);

  const setData = (e) => {
    const { name, value } = e.target;
    setOrganizationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddData = () => {
    setStoreData((prevData) => [...prevData, organizationData]);
    setOrganizationData({
      organizationName: "",
      organizationCity: "",
      organizationAddress: "",
      organizationEmail: "",
      organizationPhone: "",
      organizationWebsite: "",
    });
    setClinicShowPopup(false);
  };

  const handleEditData = (index) => {
    setEditData({ ...storeData[index], index });
    setEditClinicShowPopup(true);
  };

  const handleUpdateData = () => {
    const updatedStoreData = [...storeData];
    updatedStoreData[editData.index] = editData;
    setStoreData(updatedStoreData);
    setEditData(null);
    setEditClinicShowPopup(false);
  };

  const handleDeleteData = (index) => {
    const updatedStoreData = [...storeData];
    updatedStoreData.splice(index, 1);
    setStoreData(updatedStoreData);
  };
  const clinicAddButtonStyles1 = {
    backgroundColor: "#7a69f5ff",
    marginTop: "1rem",
    textTransform: "none",
    fontSize: "16px",
    marginLeft: "0.7em",
  };
  const clinicAddButtonStyles2 = {
    backgroundColor: "#7a69f5ff",
    marginTop: "1rem",
    textTransform: "none",
    fontSize: "16px",
    marginLeft: "0.7em",
    color: "white",
  };
  return (
    <>
      <div className="clinics-details-container">
        <Typography
          variant="h6"
          sx={{ color: isDarkTheme ? "white" : "black" }}
          className="clinics-heading"
        >
          Organization Info
        </Typography>
        <div
          className={
            isDarkTheme
              ? "clinics-detail-body-dark"
              : "clinics-detail-body-light"
          }
        >
          <div
            className={
              isDarkTheme
                ? "clinics-detail-body-item-dark"
                : "clinics-detail-body-item-light"
            }
          >
            <label htmlFor="orgainization-name">Organization Name</label>
            <input
              type="text"
              name="organizationName"
              value={organizationData.organizationName}
              onChange={setData}
            />
          </div>
          <div
            className={
              isDarkTheme
                ? "clinics-detail-body-item-dark"
                : "clinics-detail-body-item-light"
            }
          >
            <label htmlFor="notification-name">Notification Emails</label>
            <input
              type="text"
              name="organizationEmail"
              value={organizationData.organizationEmail}
              onChange={setData}
            />
          </div>
        </div>
      </div>
      <div className="edt-clinics-details">
        {storeData.map((data, index) => {
          return (
            <div className="organization-data-item" key={index}>
              <div className="organization-data-item-left">
                <div>
                  <p style={{ color: isDarkTheme ? "white" : "black" }}>
                    {data.organizationName}
                  </p>
                  <p style={{ color: isDarkTheme ? "white" : "black" }}>
                    {data.organizationCity}
                  </p>
                  <p style={{ color: isDarkTheme ? "white" : "black" }}>
                    {data.organizationAddress}
                  </p>
                  <p style={{ color: isDarkTheme ? "white" : "black" }}>
                    {data.organizationEmail}
                  </p>
                  <p style={{ color: isDarkTheme ? "white" : "black" }}>
                    {data.organizationPhone}
                  </p>
                  <p style={{ color: isDarkTheme ? "white" : "black" }}>
                    {data.organizationWebsite}
                  </p>
                </div>
              </div>

              <div className="organization-data-item-right">
                <Button
                  style={{
                    backgroundColor: "#7a69f5ff",
                  }}
                  className="edit__clinic"
                  variant="contained"
                  onClick={() => handleEditData(index)}
                >
                  <EditIcon
                    style={{
                      fontSize: "19px",
                      color: "yellowgreen",
                      opacity: "0.8",
                      marginRight: "3px",
                    }}
                  />{" "}
                  Edit Clinics
                </Button>
                <DeleteIcon
                  style={{ backgroundColor: "#7a69f5ff" }}
                  onClick={() => handleDeleteData(index)}
                />
              </div>
            </div>
          );
        })}
        {showEditClinicPopup && editData && (
          <Popup isDarkTheme={isDarkTheme}>
            <div className="edit-clinic-details">
              <div className="edit-clinic-details-header">
                <CloseIcon
                  style={{ color: isDarkTheme ? "white" : "black" }}
                  onClick={() => setEditClinicShowPopup(false)}
                />
              </div>
              <form onSubmit={handleUpdateData}>
                <div
                  className={
                    isDarkTheme
                      ? "edit-clinic-details-body-dark"
                      : "edit-clinic-details-body-light"
                  }
                >
                  <Typography
                    variant="h6"
                    className={
                      isDarkTheme
                        ? "edit-clinic-heading-dark"
                        : "edit-clinic-heading-light"
                    }
                  >
                    Edit Clinic
                  </Typography>
                  <div
                    className={
                      isDarkTheme
                        ? "edit-clinic-details-body-item-dark"
                        : "edit-clinic-details-body-item-light"
                    }
                  >
                    <label htmlFor="clinic-name">Clinic Name</label>
                    <input
                      type="text"
                      name="organizationName"
                      value={editData.organizationName}
                      onChange={(e) =>
                        setEditData((prevData) => ({
                          ...prevData,
                          organizationName: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div
                    className={
                      isDarkTheme
                        ? "edit-clinic-details-body-item-dark"
                        : "edit-clinic-details-body-item-light"
                    }
                  >
                    <label htmlFor="clinic-city">City</label>
                    <input
                      type="text"
                      name="organizationCity"
                      value={editData.organizationCity}
                      onChange={(e) =>
                        setEditData((prevData) => ({
                          ...prevData,
                          organizationCity: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div
                    className={
                      isDarkTheme
                        ? "edit-clinic-details-body-item-dark"
                        : "edit-clinic-details-body-item-light"
                    }
                  >
                    <label htmlFor="clinic-address">Address</label>
                    <textarea
                      id="w3review"
                      name="organizationAddress"
                      rows="6"
                      cols="50"
                      value={editData.organizationAddress}
                      onChange={(e) =>
                        setEditData((prevData) => ({
                          ...prevData,
                          organizationAddress: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                  <div
                    className={
                      isDarkTheme
                        ? "edit-clinic-details-body-item-dark"
                        : "edit-clinic-details-body-item-light"
                    }
                  >
                    <label htmlFor="clinic-email">Email</label>
                    <input
                      type="text"
                      name="organizationEmail"
                      value={editData.organizationEmail}
                      onChange={(e) =>
                        setEditData((prevData) => ({
                          ...prevData,
                          organizationEmail: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div
                    className={
                      isDarkTheme
                        ? "edit-clinic-details-body-item-dark"
                        : "edit-clinic-details-body-item-light"
                    }
                  >
                    <label htmlFor="clinic-phone">Phone</label>
                    <input
                      type="text"
                      name="organizationPhone"
                      value={editData.organizationPhone}
                      onChange={(e) =>
                        setEditData((prevData) => ({
                          ...prevData,
                          organizationPhone: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div
                    className={
                      isDarkTheme
                        ? "edit-clinic-details-body-item-dark"
                        : "edit-clinic-details-body-item-light"
                    }
                  >
                    <label htmlFor="clinic-website">Website</label>
                    <input
                      type="text"
                      name="organizationWebsite"
                      value={editData.organizationWebsite}
                      onChange={(e) =>
                        setEditData((prevData) => ({
                          ...prevData,
                          organizationWebsite: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <Button type="submit" style={clinicAddButtonStyles2}>
                  Update Clinic
                </Button>
              </form>
            </div>
          </Popup>
        )}
      </div>
      <Button
        variant="contained"
        onClick={() => setClinicShowPopup(true)}
        style={clinicAddButtonStyles1}
      >
        Add Clinic
      </Button>
      {showClinicPopup && (
        <Popup isDarkTheme={isDarkTheme}>
          <div className="add-clinic-details">
            <div className="add-clinic-details-header">
              <CloseIcon
                style={{ color: isDarkTheme ? "white" : "black" }}
                onClick={() => setClinicShowPopup(false)}
              />
            </div>
            <form onSubmit={handleAddData}>
              <Typography
                variant="h6"
                className={
                  isDarkTheme
                    ? "add-clinic-heading-dark"
                    : "add-clinic-heading-light"
                }
              >
                Add Clinic
              </Typography>
              <div
                className={
                  isDarkTheme
                    ? "add-clinic-details-body-dark"
                    : "add-clinic-details-body-light"
                }
              >
                <div
                  className={
                    isDarkTheme
                      ? "add-clinic-details-body-item-dark"
                      : "add-clinic-details-body-item-light"
                  }
                >
                  <label htmlFor="clinic-name">Clinic Name</label>
                  <input
                    type="text"
                    name="organizationName"
                    value={organizationData.organizationName}
                    onChange={setData}
                    required
                  />
                </div>
                <div
                  className={
                    isDarkTheme
                      ? "add-clinic-details-body-item-dark"
                      : "add-clinic-details-body-item-light"
                  }
                >
                  <label htmlFor="clinic-city">City</label>
                  <input
                    type="text"
                    name="organizationCity"
                    value={organizationData.organizationCity}
                    onChange={setData}
                  />
                </div>
                <div
                  className={
                    isDarkTheme
                      ? "add-clinic-details-body-item-dark"
                      : "add-clinic-details-body-item-light"
                  }
                >
                  <label htmlFor="clinic-address">Address</label>
                  <textarea
                    id="w3review"
                    name="organizationAddress"
                    rows="6"
                    cols="50"
                    value={organizationData.organizationAddress}
                    onChange={setData}
                  ></textarea>
                </div>
                <div
                  className={
                    isDarkTheme
                      ? "add-clinic-details-body-item-dark"
                      : "add-clinic-details-body-item-light"
                  }
                >
                  <label htmlFor="clinic-email">Email</label>
                  <input
                    type="text"
                    name="organizationEmail"
                    value={organizationData.organizationEmail}
                    onChange={setData}
                  />
                </div>
                <div
                  className={
                    isDarkTheme
                      ? "add-clinic-details-body-item-dark"
                      : "add-clinic-details-body-item-light"
                  }
                >
                  <label htmlFor="clinic-phone">Phone</label>
                  <input
                    type="text"
                    name="organizationPhone"
                    value={organizationData.organizationPhone}
                    onChange={setData}
                  />
                </div>
                <div
                  className={
                    isDarkTheme
                      ? "add-clinic-details-body-item-dark"
                      : "add-clinic-details-body-item-light"
                  }
                >
                  <label htmlFor="clinic-website">Website</label>
                  <input
                    type="text"
                    name="organizationWebsite"
                    value={organizationData.organizationWebsite}
                    onChange={setData}
                  />
                </div>
                <Button
                  type="submit"
                  sx={{ ml: 3 }}
                  style={clinicAddButtonStyles2}
                >
                  Add Clinic
                </Button>
              </div>
            </form>
          </div>
        </Popup>
      )}
    </>
  );
};

export default ClinicsDetail;
