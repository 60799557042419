import React from 'react'
import "./ToothCard.css"
import toothImage from "../../Assets/images/commonImages/cardPhoto.jpg"

const ToothCard = (props) => {
  return (
    <div className={props.isDarkTheme ? "tooth-card-main-dark" : "tooth-card-main-light"}>
      <div className={props.isDarkTheme ? "tooth-card-head-dark" : "tooth-card-head-light"}>
        <h4>Teeth {props.toothNumber}</h4>
      </div>
      <div className="tooth-card-body">
        <img src={toothImage} alt="" />
        <img src={toothImage} alt="" />
        <img src={toothImage} alt="" />
        <img src={toothImage} alt="" />
        <img src={toothImage} alt="" />
        <img src={toothImage} alt="" />
        <img src={toothImage} alt="" />
        <img src={toothImage} alt="" />
      </div>
      <div className="tooth-card-foot"></div>
    </div>
  )
}

export default ToothCard
