import React, { useState, useContext, useRef, useEffect } from "react";
// import "./Navbar.css";
import styled from "@emotion/styled";
import { ThemeContext } from "../../ThemeContext";
import logo from "../../Assets/images/LoginPage/PerioxaoLogo.png";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsRounded from "@mui/icons-material/SettingsRounded";
import { Link, useMatch } from "react-router-dom";
import LightModeIcon from "@mui/icons-material/LightMode";
import ProfileImage from "../../Assets/images/commonImages/Person1.png";
import { ReactComponent as EditProfileIcon } from "./editProfileIcon.svg";
import { ReactComponent as LogOutIcon } from "./LogoutIcon.svg";
import { toast } from "react-toastify";
import { device } from "../../shared/BreakPoints";
import { useAuth } from "./../../contexts/AuthContext";

import "react-toastify/dist/ReactToastify.css";
//Icons
import MenuIcon from "@mui/icons-material/Menu";
function Navbar({ backgroundColor, linkColor, iconColor }) {
  const { logout } = useAuth();
  let headerHistory = null;
  let dashboard = useMatch("/");
  let patientOverview = useMatch("/patientOverview");
  let patientHistory = useMatch("/patientHistory");
  let profile = useMatch("/profile");
  let allPatients = useMatch("/allPatients");
  let anottedChangeNumber = useMatch("/anottedChangeNumber");
  let dashanottedRegionOfInterestboard = useMatch("/anottedRegionOfInterest");
  let anottedClick = useMatch("/anottedClick");
  let report = useMatch("/report");
  let cbctReport = useMatch("/cbctReport");
  let downloadReport = useMatch("/downloadReport");
  let showSlices = useMatch("/showSlices");
  let comments = useMatch("/comments");
  let tasks = useMatch("/tasks");
  let collaborationTools = useMatch("/collaborationTools");
  let setting = useMatch("/setting");

  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);
  const [showLinks, setShowLinks] = useState(false);

  if (
    dashboard ||
    patientOverview ||
    patientHistory ||
    allPatients ||
    anottedChangeNumber ||
    dashanottedRegionOfInterestboard ||
    anottedClick ||
    report ||
    cbctReport ||
    downloadReport ||
    showSlices ||
    comments ||
    tasks ||
    collaborationTools ||
    setting
  ) {
    headerHistory = true;
  }

  const iconStyle = {
    color: iconColor,
    fontSize: "24px",
  };
  const iconsdivstyle = {
    backgroundColor: "#D9D9D9",
    borderRadius: "100%",
    gap: "2%",
    padding: "4px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  };

  const [showDropdown, setshowDropdown] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setshowDropdown(false);
      }
    }

    function handleEscapeKey(event) {
      if (event.keyCode === 27) {
        setshowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const hadleMenuDropdown = () => {
    setshowDropdown((prevState) => !prevState);
  };
  const handleLogout = () => {
    logout();
    localStorage.clear();
    toast.success("User logged out");
  };
  return (
    <NavbarContainer
      props={headerHistory}
      backgroundColor={backgroundColor}
      linkColor={linkColor}
      isDarkTheme2={isDarkTheme}
    >
      <div className="navber-left">
        <div className="brand">
          <Link to="/">
            <img src={logo} className="logo" alt="logo" />
          </Link>
        </div>

        <ul className="list" id={showLinks ? "hidden" : ""}>
          <li>
            <Link to="/allPatients" onClick={() => setShowLinks(!showLinks)}>
              Patients
            </Link>
          </li>

          <li>
            <Link to="/report" onClick={() => setShowLinks(!showLinks)}>
              Reports
            </Link>{" "}
          </li>
          <li>
            <Link to="/tasks" onClick={() => setShowLinks(!showLinks)}>
              Tasks
            </Link>{" "}
          </li>
          <li>
            <Link to="/comments" onClick={() => setShowLinks(!showLinks)}>
              Comments
            </Link>{" "}
          </li>
        </ul>
        <MenuIcon
          className="menu_icon"
          onClick={() => setShowLinks(!showLinks)}
        />
      </div>
      <div className="navbar-right">
        <div style={iconsdivstyle}>
          <SearchOutlinedIcon style={iconStyle} />
        </div>
        <div style={iconsdivstyle}>
          <NotificationsNoneOutlinedIcon style={iconStyle} />
        </div>
        <div style={iconsdivstyle}>
          {isDarkTheme ? (
            <LightModeIcon onClick={toggleTheme} style={iconStyle} />
          ) : (
            <DarkModeOutlinedIcon
              onClick={toggleTheme}
              style={{ color: "black" }}
            />
          )}
        </div>
        <Link to="/setting">
          <div style={iconsdivstyle}>
            <SettingsRounded style={iconStyle} />
          </div>
        </Link>

        <div className="profile-pic-arrow">
          <img
            src={profile}
            className="profile-pic"
            alt=""
            onClick={hadleMenuDropdown}
          />
        </div>
        {showDropdown && (
          <div
            ref={popupRef}
            className={
              isDarkTheme ? "dropDownProfile-dark" : "dropDownProfile-light "
            }
          >
            <div className="dropDownHeader">
              <p className="yourProfileText">Your Profile</p>
              <Link to="/profile">
                <EditProfileIcon onClick={hadleMenuDropdown} />
              </Link>
            </div>
            <div className="dropDownProfileInfo">
              <img src={ProfileImage} className="personProfileImage" alt="" />
              <div className="dropDownProfileInfoRight">
                <p className="dropDownProfileDoctorName">Doctor Name</p>
                <p className="dropDownProfileDoctorID">Doctor ID</p>
                <p className="dropDownProfileDoctorEmail">Doctor Email</p>
              </div>
            </div>
            <div className="dropDownProfileAbout">
              <h5>About</h5>
              <p>About Text</p>
            </div>
            <div className="dropDownProfileContact">
              <h5>Contact</h5>
              <p>Contact Info</p>
            </div>
            <hr />
            <div className="dropDownProfileLogout">
              <Link to={"login"} onClick={handleLogout}>
                <LogOutIcon /> <p>Logout</p>
              </Link>
            </div>
          </div>
        )}
      </div>
    </NavbarContainer>
  );
}

const NavbarContainer = styled.section`
  display: ${(props) => (props.props ? "flex" : "none")};
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 10vh;
  z-index: 1000;
  background-color: ${(props) => (props.isDarkTheme2 ? "black" : "white")};
  color: ${(props) => (props.isDarkTheme2 ? "white" : "black")};

  /* Navbar styles */
  .navber-left {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .brand {
    width: 30%;
    text-align: center;
  }
  .logo {
    width: 80%;
  }

  .profile-pic {
    width: 3em;
    height: 3em;
    border-radius: 50%;
  }

  .list {
    width: 60%;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    list-style-type: none;
    font-weight: 700;
    font-size: 14px;
    padding: 1rem 0;
    margin-top: 0;
    padding-top: 0;
  }
  .menu_icon {
    color: black;
    display: none;
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: #7a69f5ff;
  }

  .navbar-right {
    width: 50%;
    display: flex;
    gap: 3%;
    justify-content: flex-end;
    align-items: center;
  }

  .profile-pic-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dropDownProfile-light {
    position: absolute;
    top: 4rem;
    right: 1.8rem;
    width: 280px;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
    border: 1px solid grey;
    z-index: 9999;
  }
  .dropDownProfile-dark {
    position: absolute;
    top: 4rem;
    right: 1.8rem;
    width: 280px;
    padding: 20px;
    border-radius: 8px;
    background-color: #2c2c2c;
    color: white;
    border: 1px solid grey;
    z-index: 9999;
  }
  .personProfileImage {
    height: 50px;
    width: 50px;
    border-radius: 100%;
  }
  .dropDownHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
  }
  .dropDownProfileInfo,
  .dropDownProfileLogout {
    display: flex;
    flex-direction: row;
  }

  .yourProfileText,
  .dropDownProfileDoctorID,
  .dropDownProfileDoctorEmail,
  .dropDownProfileAbout > p,
  .dropDownProfileContact > p {
    font-size: 12px;
    color: grey;
  }
  .dropDownProfileDoctorName {
    font-size: 16px;
    font-weight: 700;
  }
  h6 {
    margin-bottom: 0;
  }
  .dropDownProfileLogout > a {
    display: flex;
    gap: 4%;
    align-items: center;
  }
  .dropDownProfile-light > hr,
  .dropDownProfile-dark > hr {
    margin: 8px;
  }
  .dropDownProfileAbout,
  .dropDownProfileContact {
    margin: 8px;
  }
  #hidden {
    display: flex;
  }
  /* Media Query */
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: ${device.tabletL}) {
    .navber-left {
      display: flex;
      justify-content: space-around;
      width: 28%;
    }
    .list {
      display: none;
    }
    .menu_icon {
      display: block;
    }
    .brand {
      width: 60%;
    }
    .logo {
      width: 100%;
    }
    #hidden {
      position: absolute;
      left: 10vw;
      top: 10vh;
      height: 10vh;
      width: 80%;
      background-color: white;
      align-items: center;
    }
    .profile-pic {
      width: 2.5em;
      height: 2.5em;
    }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: ${device.mobileL}) {
  }
`;

export default Navbar;
