import React, { Fragment, useContext } from "react";
import "./header.css";
import { ThemeContext } from "../../../ThemeContext";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
function Header() {
  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <Fragment>
      <div className={isDarkTheme ? "header-container-dark" : "header-container-light"} >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", mb: "0px" }}
        >
          Monica Adamson
        </Typography>
        <p>
          <strong>January 12 1998, 33 y.o ID 23965</strong>
        </p>
        <div className="buttons-section">
          <div className="button-section-left">
            <Button
              variant="contained"
              size="small"
              style={{ backgroundColor: "#1B64C8", textTransform: "none" }}
            >
              Overview
            </Button>
            <Link to="/patientHistory">
            <Button
              size="small"
              style={{ color: "black", backgroundColor: " #D9DCE3ff", textTransform: "none" }}
              sx={{ m: 0.5 }}
              >
              History
            </Button>
              </Link>
          </div>
          <div className="button-section-right">
            <Button
              style={{
                backgroundColor: " #D9DCE3ff",
                padding: "7px",
                minWidth: "0px",
                margin: "3px"
              }}
            >
              <SearchOutlinedIcon style={{fontSize: "18px"}} />
            </Button>
            <Button
              variant="contained"
              size="small"
              style={{ backgroundColor: "#1B64C8", textTransform: "none"}}
            >
              Upload new study
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Header;
