import React, { useState } from "react";
import "./CBCTReportPageBody.css";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import image from "../../../Assets/images/commonImages/card1.jpg";
import person1 from "../../../Assets/images/commonImages/Person1.png";
import person2 from "../../../Assets/images/commonImages/Person2.jpg";
import person3 from "../../../Assets/images/commonImages/Person3.jpg";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from "@mui/icons-material/Close";

function Popup(props) {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: "9999",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(129, 116, 214, 0.8)",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "55%",
          left: "45.1%",
          width: "40vw",
          minHeight: "70vh",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#FCFCFC",
          borderRadius: "0.5rem",
          padding: "1rem",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

const CBCTReportPageBody = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChange = (checked) => {
    setChecked(checked);
  };
  let patients = [
    {
      name: "Matt Smith",
      profile: person1,
    },
    {
      name: "Peter Parker",
      profile: person2,
    },
    {
      name: "Monica Carrington",
      profile: person3,
    },
    {
      name: "Nicole Clifford",
      profile: person1,
    },
  ];

  let orthodontic = [
    {
      name: "Matt Smith",
      profile: person1,
    },
    {
      name: "Peter Parker",
      profile: person2,
    },
  ];
  let implanetry = [
    {
      name: "Matt Smith",
      profile: person1,
    },
  ];
  return (
    <div className="cbct-page-body-container">
      <div className="cbct-page-body-header">
        <div className="cbct-page-body-header-left">
          <Typography variant="h5" className="cbct-date-text">
            May 4, 2022
          </Typography>
          <Button variant="contained" className="button-active">
            My 23
          </Button>
          <Button variant="contained" className="button-deactive">
            Favouites 1
          </Button>
          <Button variant="contained" className="button-deactive">
            All 25
          </Button>
        </div>
        <div className="cbct-page-body-header-right">
          <Button
            variant="contained"
            className="button-order-report"
            onClick={() => setShowPopup(true)}
          >
            <AddIcon /> Order New Report
          </Button>
        </div>
      </div>
      <div className="cbct-page-body">
        <div className="radiological-report-section">
          <Typography variant="h6" sx={{ pl: 2, fontWeight: "bold" }}>
            Radiological Report {patients.length}
          </Typography>
          {patients.map((text) => (
            <div className="radiological-report-section-item">
              <div style={{ display: "flex" }}>
                <div
                  className="first"
                  style={{ display: "flex", width: "25vw" }}
                >
                  <StarOutlineIcon
                    style={{ color: "black", marginTop: "10px" }}
                  />
                  <img
                    src={text.profile}
                    alt=""
                    className="patient-profile-image"
                  />
                  <p className="cbct-patient-name-text">Patient123</p>
                  <p className="cbct-patient-date-text">May 20, 2022</p>
                </div>
                <div className="avatars">
                  <span className="avatar">
                    <img
                      src={person1}
                      alt=""
                      className="patient-profile-image-circle"
                    />
                  </span>
                  <span className="avatar">
                    <img
                      src={person2}
                      alt=""
                      className="patient-profile-image-circle"
                    />
                  </span>
                </div>
              </div>
              <div
                className="third"
                style={{
                  width: "16vw",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {/* <p>1/3 Loading Panorma...</p> */}
                <p className="cbct-signed">
                  {" "}
                  <DoneIcon style={{ color: "greenyellow" }} /> Signed{" "}
                </p>
                <div>
                  <span class="count-icon" sx={{ m: 2 }}>
                    <ChatBubbleIcon style={{ color: "#F1EEFB" }} />
                    <span class="count">12</span>
                  </span>
                  <MoreHorizIcon
                    style={{ color: "black", marginLeft: "10px" }}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="cbct-add-report-btn-container">
            <Button variant="contained" className="add-item-button">
              <AddIcon style={{ color: "#594DB2" }} /> Add Report
            </Button>
          </div>
        </div>
        <div className="radilogical-report-section">
          <Typography variant="h6" sx={{ pl: 2, fontWeight: "bold" }}>
            Orthodontic Report {orthodontic.length}
          </Typography>
          {orthodontic.map((text) => (
            <div className="radiological-report-section-item">
              <div style={{ display: "flex" }}>
                <div
                  className="first"
                  style={{ display: "flex", width: "25vw" }}
                >
                  <StarOutlineIcon
                    style={{ color: "black", marginTop: "10px" }}
                  />
                  <img
                    src={text.profile}
                    alt=""
                    className="patient-profile-image"
                  />
                  <p className="cbct-patient-name-text">Patient123</p>
                  <p className="cbct-patient-date-text">May 20, 2022</p>
                </div>
                <div className="avatars">
                  <span className="avatar">
                    <img
                      src={person1}
                      alt=""
                      className="patient-profile-image-circle"
                    />
                  </span>
                </div>
              </div>
              <div className="third" style={{ width: "18vw" }}>
                <p>1/3 Loading Panorma...</p>
              </div>
            </div>
          ))}
          <div className="cbct-add-report-btn-container">
            <Button variant="contained" className="add-item-button">
              <AddIcon style={{ color: "#594DB2" }} /> Add Report
            </Button>
          </div>
        </div>
        <div className="radilogical-report-section">
          <Typography variant="h6" sx={{ pl: 2, fontWeight: "bold" }}>
            Implantery Report {implanetry.length}
          </Typography>
          {implanetry.map((text) => (
            <div className="radiological-report-section-item">
              <div style={{ display: "flex" }}>
                <div
                  className="first"
                  style={{ display: "flex", width: "25vw" }}
                >
                  <StarOutlineIcon
                    style={{ color: "black", marginTop: "10px" }}
                  />
                  <img
                    src={text.profile}
                    alt=""
                    className="patient-profile-image"
                  />
                  <p className="cbct-patient-name-text">Patient123</p>
                  <p className="cbct-patient-date-text">May 20, 2022</p>
                </div>
                <div className="avatars">
                  <span className="avatar">
                    <img
                      src={person2}
                      alt=""
                      className="patient-profile-image-circle"
                    />
                  </span>
                </div>
              </div>
              <div className="third" style={{ width: "18vw" }}>
                <p>1/3 Loading Panorma...</p>
              </div>
            </div>
          ))}
          <div className="cbct-add-report-btn-container">
            <Button variant="contained" className="add-item-button">
              <AddIcon style={{ color: "#594DB2" }} /> Add Report
            </Button>
          </div>
        </div>
      </div>
      {showPopup && (
        <Popup>
          <div className="pop-cbct-header">
            <Typography variant="h5" sx={{ fontWeight: "300" }}>
              Order New
            </Typography>
            <CloseIcon
              onClick={() => setShowPopup(false)}
              style={{ color: "black" }}
            />
          </div>
          <div className="pop-cbct-menu">
            <div className="pop-cbct-menu-left">
            <img src={person1} alt="" className="patient-profile-image" />
                  <p className="cbct-patient-date-text">May 20, 2022</p>
            <p className="cbct-patient-name-text">Patient123</p>
            </div>
            <ExpandMoreIcon style={{color: "black", }} />
          </div>
          <div className="cbct-pop-body">
            <div className="cbct-pop-body-item">
              <p>Radiological Report</p>
            </div>
            <div className="cbct-pop-body-item">
              <p>Endodontic Study</p>
            </div>
            <div className="cbct-pop-body-item">
              <p>Third Molar Study</p>
            </div>
            <div className="cbct-pop-body-item">
              <p>Orthodontic Report</p>
            </div>
            <div className="cbct-pop-body-item">
              <p>Implantology Study</p>
            </div>
            <div className="cbct-pop-body-item">
              <p>3D Model</p>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default CBCTReportPageBody;
