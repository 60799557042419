import { useState } from "react";

const useAddPatientData = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setdateOfBirth] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [referDoctor, setReferDoctor] = useState([]);
  const [Gender, setGender] = useState("");

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    dateOfBirth,
    setdateOfBirth,
    contactNo,
    setContactNo,
    Gender,
    setGender,
    referDoctor,
    setReferDoctor,
  };
};

export default useAddPatientData;
