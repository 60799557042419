import React, {useContext} from "react";
import {ThemeContext} from "../../ThemeContext"
import teethSlice from "./TeethSlice.jpg";
import "./ShowSlices.css";
import SliceSidebar from "./sliceSidebar/SliceSidebar"

const Pictures = [
  {
    slicePath: "teethSlice",
  },
  {
    slicePath: "teethSlice",
  },
  {
    slicePath: "teethSlice",
  },
  {
    slicePath: "teethSlice",
  },
  {
    slicePath: "teethSlice",
  },
  {
    slicePath: "teethSlice",
  },
  {
    slicePath: "teethSlice",
  },
  {
    slicePath: "teethSlice",
  },
  {
    slicePath: "teethSlice",
  },
];
const ShowSlices = () => {
  const {isDarkTheme} = useContext(ThemeContext)
  return (

    <div className= "slice-page-main">
        <SliceSidebar isDarkTheme={isDarkTheme} />
    <div className={isDarkTheme ? "slice-page-dark" : "slice-page-light"}>
      <h6>Front View</h6>
      <div className="slice-section-1">
        {Pictures.map((data) => {
          return (
            <div className="slice-section-1-images">
              <img src={teethSlice} alt="" />
            </div>
          );
        })}
      </div>
      <h6>Axial View</h6>
      <div className="slice-section-1">
        {Pictures.map((data) => {
          return (
            <div className="slice-section-1-images">
              <img src={teethSlice} alt="" />
            </div>
          );
        })}
      </div>
      <h6>Missia distal view</h6>
      <div className="slice-section-1">
        {Pictures.map((data) => {
          return (
            <div className="slice-section-1-images">
              <img src={teethSlice} alt="" />
            </div>
          );
        })}
      </div>
    </div>
    </div>
  );
};

export default ShowSlices;
